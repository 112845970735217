import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import StartStack from "../stacks/StartStack";
import { AppRoutes } from "../constants/AppRoutes";
import { PrivateRoute } from "../components/routing/PrivateRoute";
import { SuspenseLayout } from "../components/app/SuspenseLayout";
import { CurrencyProvider } from "../components/currency/CurrencyProvider";
import { NoInternetConnection } from "../pages/NoInternetConnection";
import { AuthProvider } from "../components/auth/AuthContext";

const SurveyStack = lazy(() => import("../stacks/SurveyStack"));
const ProfileStack = lazy(() => import("../stacks/ProfileStack"));
const DashboardStack = lazy(() => import("../stacks/DashboardStack"));
const DocumentStack = lazy(() => import("../stacks/DocumentsStack"));

export function RootContainer() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOnline) {
    return <NoInternetConnection />;
  }
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={AppRoutes.DashboardRoot}
            element={
              <PrivateRoute
                component={() => (
                  <Suspense fallback={<SuspenseLayout />}>
                    <CurrencyProvider>
                      <DashboardStack />
                    </CurrencyProvider>
                  </Suspense>
                )}
              />
            }
          />
          <Route
            path={AppRoutes.ProfileRoot}
            element={
              <PrivateRoute
                component={() => (
                  <Suspense fallback={<SuspenseLayout />}>
                    <CurrencyProvider>
                      <ProfileStack />
                    </CurrencyProvider>
                  </Suspense>
                )}
              />
            }
          />
          <Route
            path={AppRoutes.DocumentRoot}
            element={
              <PrivateRoute
                component={() => (
                  <Suspense fallback={<SuspenseLayout />}>
                    <DocumentStack />
                  </Suspense>
                )}
              />
            }
          />
          <Route path={AppRoutes.StartRoot} element={<StartStack />} />
          <Route
            path={AppRoutes.Survey}
            element={
              <PrivateRoute
                component={() => (
                  <Suspense fallback={<SuspenseLayout />}>
                    <SurveyStack />
                  </Suspense>
                )}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
