import { omit } from "lodash";
import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";
import { HttpMethod } from "../dto/ApplicationDTO";

enum CommonApiTypes {
  Agreement = "agreement",
  RiskAgreement = "risk-agreement",
  AugustInfo = "august-info",
  CurrenciesList = "currencies-list",
  CallbackAvailableTimes = "callback-available-times",
}

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: fetchQuery(),
  tagTypes: [
    CommonApiTypes.Agreement,
    CommonApiTypes.AugustInfo,
    CommonApiTypes.CurrenciesList,
    CommonApiTypes.CallbackAvailableTimes,
    CommonApiTypes.RiskAgreement,
  ],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<Application.ResponseProps<Common.FileProps>, FormData>({
      query: (query) => ({
        url: createUrl(ApiRoutes.CommonUploadFile, { query: omit(query, "body") }),
        method: HttpMethod.Post,
        body: query,
      }),
    }),
    augustInfo: builder.query<Application.ResponseProps<August.AugustInfoResponse>, void>({
      query: () => ApiRoutes.AugustInfo,
      providesTags: [CommonApiTypes.AugustInfo],
    }),
    conversionInfo: builder.query<Application.ResponseProps<August.ConversionResponse>, void>({
      query: () => ApiRoutes.ConversionInfo,
    }),
    currencyList: builder.query<
      Application.ResponseProps<Currency.Props[]>,
      Currency.GetCurrenciesFilterProps | void
    >({
      query: (query) => createUrl(ApiRoutes.CurrencyList, { query }),
      providesTags: [CommonApiTypes.CurrenciesList],
    }),
    agreement: builder.query<Application.ResponseProps<Common.AgreementProps>, void>({
      query: (query) => createUrl(ApiRoutes.Agreement, { query }),
      providesTags: [CommonApiTypes.Agreement],
    }),
    getRiskAgreement: builder.query<Application.ResponseProps<Common.AgreementProps>, void>({
      query: (query) => createUrl(ApiRoutes.RiskAgreement, { query }),
      providesTags: [CommonApiTypes.RiskAgreement],
    }),
    callback: builder.mutation<Application.ResponseProps, Common.CallbackBodyProps>({
      query: (query) => createUrl(ApiRoutes.Callback, { query }),
    }),
    callbackAvailableTimes: builder.query<
      Application.ResponseProps<string[]>,
      Common.CallbackAvailableTimesBodyProps
    >({
      query: (query) => createUrl(ApiRoutes.CallbackAvailableTimes, { query }),
      providesTags: [CommonApiTypes.CallbackAvailableTimes],
    }),
  }),
});

export const {
  useUploadFileMutation,
  useLazyGetRiskAgreementQuery,
  useCurrencyListQuery,
  useLazyCurrencyListQuery,
  useAgreementQuery,
  useCallbackMutation,
  useAugustInfoQuery,
  useLazyCallbackAvailableTimesQuery,
  useConversionInfoQuery,
} = commonApi;

/**
 * Selectors
 */

export const selectCurrenciesResult = commonApi.endpoints.currencyList.select();
