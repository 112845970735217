import cx from "classnames";
import React, { PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import {
  makeStyles,
  useIsLaptopUp,
  useIsMobileUp,
  useIsTabletUp,
  useTheme,
} from "../../theme/Theme";
import { RoundBackButton } from "../buttons/RoundBackButton";
import { AngleLeftIcon } from "../icons/AngleLeftIcon";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { Spacer } from "../ui/Spacer";
import { Stack } from "@mui/material";
import classNames from "classnames";

interface StylesProps {
  isLaptopUp: boolean;
  isMobileUp: boolean;
  isTabletUp: boolean;
}
const useStyles = makeStyles<StylesProps>((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 30,
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      alignItems: "center",
      flexDirection: "row",
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  backButton: {
    marginRight: theme.typography.pxToRem(10),
  },
  backButtonTablet: {
    cursor: "pointer",
    marginRight: theme.typography.pxToRem(20),
  },
  title: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    cursor: (props) => (props.isTabletUp ? "pointer" : "initial"),
  },
  clickable: {
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    textDecoration: "none",
    alignItems: "center",
    display: "flex",
    userSelect: "none",
    appearance: "none",
  },
}));

interface Props {
  onBack: () => void;
  classNamesProps?: string;
}

export function ManageFundsHeader({ onBack, classNamesProps, children }: PropsWithChildren<Props>) {
  const isMobileUp = useIsMobileUp();
  const isTabletUp = useIsTabletUp();
  const isLaptopUp = useIsLaptopUp();

  const theme = useTheme();
  const classes = useStyles({ isTabletUp, isLaptopUp, isMobileUp });
  if (isLaptopUp) {
    return (
      <div className={classNames(classes.header, classNamesProps)}>
        <div onClick={onBack} className={cx(classes.clickable)}>
          <AngleLeftIcon color={theme.palette.gary.main} className={classes.backButton} />
          <Typography color={theme.palette.gary.main} variant="button1">
            Назад
          </Typography>
        </div>
        <Spacer size={30} />
        <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
          {children}
        </Stack>
      </div>
    );
  }
  if (isTabletUp) {
    return (
      <div className={classNames(classes.header, classNamesProps)}>
        <AngleLeftIcon
          onClick={onBack}
          color={theme.palette.gary.main}
          className={classes.backButtonTablet}
        />
        {children}
      </div>
    );
  }
  if (isMobileUp) {
    return (
      <div className={classNames(classes.header, classNamesProps)}>
        <RoundBackButton onClick={onBack} />
        <Spacer size={20} />
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          {children}
        </Stack>
      </div>
    );
  }
  return null;
}
