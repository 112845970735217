import { ReactNode } from "react";
import { makeStyles } from "../../theme/Theme";
import Typography from "@mui/material/Typography";
import { Spacer } from "./Spacer";
import classNames from "classnames";

interface Props {
  topTitle: string;
  bottomText?: string;
  children?: ReactNode;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapperContent: {
    width: "100%",
  },
  topText: {
    opacity: 0.65,
    color: theme.palette.cow.main,
  },
  bottomText: {
    opacity: 0.5,
    color: theme.palette.gary.main,
    display: "block",
  },
  disabled: {
    opacity: 0.65,
  },
}));
export const WrapperWithTitle = ({ topTitle, bottomText, disabled, children }: Props) => {
  const styles = useStyles();
  return (
    <div className={classNames(styles.root, { [styles.disabled]: disabled })}>
      <Typography variant={"text2"} className={styles.topText}>
        {topTitle}
      </Typography>
      {topTitle && <Spacer size={7} />}
      <div className={styles.wrapperContent}>{children}</div>
      {bottomText && (
        <>
          <Spacer size={10} />
          <Typography variant={"text2"} className={styles.bottomText}>
            {bottomText}
          </Typography>
        </>
      )}
    </div>
  );
};
