import { useEffect, useState } from "react";

const WINDOW_RESIZE_EVENT = "resize";

interface ReturnProps {
  readonly width: number;
  readonly height: number;
}

export function useDimensions(): ReturnProps {
  const [width, setWidth] = useState(window.screen.availWidth);
  const [height, setHeight] = useState(window.screen.availHeight);

  function listenerHandler() {
    setWidth(this.screen.availWidth);
    setHeight(this.screen.availHeight);
  }

  useEffect(() => {
    window.addEventListener(WINDOW_RESIZE_EVENT, listenerHandler);

    return () => {
      window.removeEventListener(WINDOW_RESIZE_EVENT, listenerHandler);
    };
  }, []);

  return { width, height };
}
