import { useTheme } from "../theme/Theme";

export function useChartColors(count?: number): string[] {
  const theme = useTheme();

  const colors = [
    theme.palette.marian.main,
    theme.palette.ocean.main,
    theme.palette.pool.main,
    theme.palette.bikiniBottom.main,
    theme.palette.wave.main,
    theme.palette.icicle.main,
    theme.palette.plant.main,
    theme.palette.grass.main,
    theme.palette.daicy.main,
    theme.palette.banana.main,
    theme.palette.icarus.main,
    theme.palette.griffin.main,
    theme.palette.sun.main,
    theme.palette.mary.main,
    theme.palette.tallaringa.main,
    theme.palette.california.main,
    theme.palette.fiji.main,
    theme.palette.ametist.main,
    theme.palette.polumne.main,
    theme.palette.lavander.main,
  ];

  if (count) {
    if (count > colors.length) {
      const repeat = Math.ceil(count / colors.length);

      return Array(repeat)
        .fill(1)
        .reduce((acc) => acc.concat(colors), []);
    }

    return colors.slice(0, count);
  }

  return colors;
}
