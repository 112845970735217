import React, { memo, useEffect } from "react";

import { makeStyles } from "../../theme/Theme";
import { ManageFundsInputPlaceholder } from "./ManageFundsInputPlaceholder";
import { ManageFundsStrategyLabel } from "./ManageFundsStrategyLabel";
import { formatCurrencyNumber } from "../../utils";
import { useGetReserveListQuery } from "../../api/manageFundsApi";
import { SelectHeader } from "../ui/select/SelectHeader";
import { noop } from "lodash";
import { PlaceholderBoxProps } from "../placeholder/PlaceholderBox";

interface Props extends Omit<PlaceholderBoxProps, "onSelect" | "onClick"> {
  readonly disabled?: boolean;
  readonly onSelect: (value: Strategy.StrategyShortProps) => void;
}

interface StylesProps {
  disabled?: boolean;
  maxPopoverHeight?: number;
}
const useStyles = makeStyles<StylesProps>((theme) => ({
  input: {
    opacity: (props) => (props.disabled ? 0.5 : 1.0),
    background: theme.palette.walter.main,
    boxShadow: theme.shadows[5],
    borderRadius: theme.typography.pxToRem(16),
    color: theme.palette.gary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid transparent",
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    cursor: "default",

    "& svg": {
      display: "none !important",
    },
  },
}));

export const ManageFundsSelectReserve = memo<Props>(({ disabled, onSelect, ...props }) => {
  const reservesResult = useGetReserveListQuery();
  const reserve = reservesResult?.data?.data && reservesResult?.data?.data[0];

  const classes = useStyles({ disabled });

  useEffect(() => {
    const selectStrategyHandler = reserve as Strategy.StrategyShortProps;
    onSelect(selectStrategyHandler);
  }, [reserve]);

  return (
    <>
      {reservesResult.isLoading ? (
        <ManageFundsInputPlaceholder {...props} />
      ) : (
        <SelectHeader
          maxWidth={props.maxWidth || "100%"}
          className={classes.input}
          disabled={disabled}
          onClick={noop}
          open={false}
        >
          <ManageFundsStrategyLabel
            title={reserve?.title || ""}
            proportion={`${reserve?.proportion}%`}
            amount={`${formatCurrencyNumber(reserve?.value.total)} ${reserve?.currency.icon}`}
          />
        </SelectHeader>
      )}
    </>
  );
});
