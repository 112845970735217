import { RefObject, useEffect, useRef } from "react";
import { off, on } from "../utils/AppUtils";

const defaultEvents = ["mousedown", "touchstart"];

export function useClickAway<E extends Event = Event>(
  refs: RefObject<HTMLElement | null>[],
  onClickAway: (event: E) => void,
  events: string[] = defaultEvents,
) {
  const savedCallback = useRef(onClickAway);
  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);
  useEffect(() => {
    const handler = (event) => {
      const clickedAnyElement = refs.every((ref) => {
        const { current: el } = ref;
        return el && !el.contains(event.target) && !el.contains(event.target.parentElement);
      });

      if (clickedAnyElement) {
        savedCallback.current(event);
      }
    };
    for (const eventName of events) {
      on(document, eventName, handler);
    }
    return () => {
      for (const eventName of events) {
        off(document, eventName, handler);
      }
    };
  }, [events, refs]);
}
