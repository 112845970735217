import React from "react";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../theme/Theme";
import { BackButton } from "../buttons/BackButton";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  backButton: {
    marginBottom: theme.typography.pxToRem(20),
  },
}));

export interface AuthFormHeaderProps {
  onBackClick: () => void;
  title: string;
}

export function AuthFormHeader({ onBackClick, title }: AuthFormHeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!false && <BackButton onClick={onBackClick} className={classes.backButton} />}

      <Typography whiteSpace="pre-wrap" variant="h1">
        {title}
      </Typography>
    </div>
  );
}
