const HIDE_PHONE_PATTERN = "99******999";

export function hidePhoneNumbers(phone: string | undefined): string {
  if (!phone) {
    return "";
  }

  let lastPatterCharIndex = 0;
  const resultArr: string[] = [];

  for (let i = 0; i < phone.length; i++) {
    const phoneChar = phone[i];

    if (!Number.isNaN(Number(phoneChar)) && phoneChar !== " ") {
      if (HIDE_PHONE_PATTERN[lastPatterCharIndex] === "*") {
        resultArr.push("*");
      } else {
        resultArr.push(phoneChar);
      }

      lastPatterCharIndex = lastPatterCharIndex + 1;
    } else {
      resultArr.push(phoneChar);
    }
  }

  return resultArr.join("");
}

interface OptionsProps {
  readonly formatWaitTitle?: (timeTitle: string, time: number) => string;
  readonly formatResendTitle?: (timeTitle: string, time: number) => string;
}

export function formatResendButtonTitle(time: number, options: OptionsProps = {}) {
  const { formatResendTitle, formatWaitTitle } = options;
  const timeTitle = () => {
    if (time > 59) {
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
    return time > 9 ? `${time} сек` : `0:${time.toString().padStart(2, "0")}`;
  };

  const waitTitle = formatWaitTitle
    ? formatWaitTitle(timeTitle(), time)
    : `Новый код через ${timeTitle()}`;
  const resendTitle = formatResendTitle
    ? formatResendTitle(timeTitle(), time)
    : "Отправить код повторно";

  return time > 0 ? waitTitle : resendTitle;
}

export function hideEmailAddress(email: string | undefined) {
  if (!email) {
    return "";
  }

  const [name, host] = email.split("@");

  return [`${name.slice(0, 2)}*****`, host].join("@");
}

interface MapSearchStringToObjectResult {
  readonly date?: string;
  readonly toDate?: string;
  readonly fromDate?: string;
}

export function mapSearchStringToObject(
  str: undefined | null | string,
): MapSearchStringToObjectResult {
  if (str == null) {
    return {};
  }

  return str
    .substring(1)
    .split("&")
    .filter(Boolean)
    .reduce((acc, item) => {
      const splitItem = item.split("=");

      return {
        ...acc,
        [splitItem[0]]: splitItem[1],
      };
    }, {});
}
