import cx from "classnames";
import { DateTime } from "luxon";
import React, { useMemo } from "react";

import { makeStyles } from "../../theme/Theme";
import { DatePickerCell } from "./DatePickerCell";
import { dateInRange, sameDate } from "../../utils";

interface StylesProps {
  readonly isRangeType: boolean;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: theme.typography.pxToRem(228),
  },
}));

interface Props extends Application.IntervalDateType {
  readonly date: DateTime;
  readonly className?: string;
  readonly isRangeType: boolean;
  readonly activeEndDate?: DateTime;
  readonly activeStartDate?: DateTime;
  readonly onChange: (dateTime: DateTime) => void;
}

export function DatePickerCalendar({
  date,
  minDate,
  maxDate,
  onChange,
  className,
  isRangeType,
  activeEndDate,
  activeStartDate,
}: Props) {
  const classes = useStyles({ isRangeType });

  const monthDays = date.daysInMonth;
  const firstDayWeekday = date.set({ day: 1 }).weekday;
  const prevMonthDays = date.minus({ month: 1 }).daysInMonth;

  const data = useMemo(() => {
    const lastRow: DateTime[] = [];

    const firstWeekDaysCount = 7 - firstDayWeekday + 1;
    const rowsCount = Math.ceil((monthDays - firstWeekDaysCount) / 7);
    const lastWeekDaysCount = monthDays - ((rowsCount - 1) * 7 + firstWeekDaysCount);

    const firstRow = Array(firstWeekDaysCount)
      .fill(0)
      .map((_, idx, arr) => date.set({ day: arr.length - idx }));

    for (let i = 0; i <= firstDayWeekday - 2; i++) {
      firstRow.push(date.minus({ month: 1 }).set({ day: prevMonthDays - i }));
    }

    for (let i = lastWeekDaysCount - 1; i >= 0; i--) {
      lastRow.push(date.set({ day: monthDays - i }));
    }

    const rows: DateTime[][] = [firstRow.reverse()];

    const lastDayFirstWeek = firstRow[6].day;

    for (let i = 0; i < rowsCount - 1; i++) {
      const firstDay = lastDayFirstWeek + i * 7 + 1;
      rows.push(
        Array(7)
          .fill(0)
          .map((_, idx) => date.set({ day: firstDay + idx })),
      );
    }

    rows.push(
      lastRow.concat(
        Array(7 - lastWeekDaysCount)
          .fill(0)
          .map((_, idx) => date.plus({ month: 1 }).set({ day: idx + 1 })),
      ),
    );

    return rows;
  }, [firstDayWeekday, monthDays, prevMonthDays, date]);

  return (
    <div className={cx(classes.root, className)}>
      {data.map((row, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {row.map((cell, cellIndex) => {
            const getIsActive = () => {
              if (isRangeType) {
                if (activeStartDate && activeEndDate) {
                  return dateInRange({
                    date: cell,
                    startDate: activeStartDate,
                    endDate: activeEndDate,
                  });
                }

                if (activeStartDate) {
                  return sameDate(cell, activeStartDate);
                }

                return activeEndDate ? sameDate(cell, activeEndDate) : false;
              }

              return activeStartDate ? sameDate(cell, activeStartDate) : false;
            };

            const isActiveMonthDay = date.month === cell.month;

            const isDisabled = !dateInRange({
              date: cell,
              startDate: minDate,
              endDate: maxDate,
            });

            return (
              <DatePickerCell
                date={cell}
                key={cellIndex}
                isActive={getIsActive()}
                disabled={isDisabled}
                onClick={() => onChange(cell)}
                isActiveMonthDay={isActiveMonthDay}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
