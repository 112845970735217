import cx from "classnames";
import { Box, BoxProps, Typography } from "@mui/material";
import { MouseEvent, ReactElement, useMemo, useState } from "react";

import { SelectDateBase } from "../date-picker/SelectDate";
import { CalendarIconMedium } from "../icons/CalendarIconMedium";
import { makeStyles, useIsTabletDown, useTheme } from "../../theme/Theme";
import { dateToFormat, getTodayDateTime, HUMAN_DATE_FORMAT } from "../../utils";

interface StylesProps {
  readonly disabled: boolean;
  readonly color: string;
  readonly open: boolean;
}

const useHeaderStyles = makeStyles<StylesProps>((theme) => ({
  input: {
    opacity: (props) => (props.disabled ? 0.5 : 1.0),
    background: theme.palette.walter.main,
    boxShadow: theme.shadows[5],
    borderRadius: theme.typography.pxToRem(16),
    color: theme.palette.gary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: (p) => (p.open ? theme.palette.grape.main : "transparent"),
    border: "1px solid transparent",
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    cursor: (props) => (props.disabled ? "default" : "pointer"),

    transition: "all 200ms ease-in-out",
    "&:hover": {
      boxShadow: (props) => (props.disabled ? theme.shadows[5] : theme.customShadows.input),
    },
  },
  icon: {
    color: (p) => p.color,
    transition: "color 200ms ease-in-out",
  },
}));

interface InputProps {
  readonly onClick: (event: MouseEvent<any>) => void;
  readonly open: boolean;
  readonly disabled: boolean;
  readonly header?: ReactElement;
}

export function SelectHeader({ onClick, open, header, disabled, ...props }: InputProps) {
  const theme = useTheme();
  const iconColor = open ? theme.palette.bloom.main : theme.palette.grape.main;
  const classes = useHeaderStyles({
    color: iconColor,
    disabled,
    open,
  });

  return (
    <Box {...props} className={classes.input} px={theme.typography.pxToRem(16)} onClick={onClick}>
      {header ? (
        header
      ) : (
        <Typography variant="input1" color={theme.palette.gary.main}>
          Выберите дату
        </Typography>
      )}
      <CalendarIconMedium color={iconColor} className={cx(classes.icon)} />
    </Box>
  );
}

interface Props extends BoxProps {
  disabled: boolean;
  date?: string | null;
  onChangeDate: (date: string) => void;
}

export function ManageFundsSelectDate({ disabled, date, onChangeDate, ...props }: Props) {
  const theme = useTheme();
  const isSmallDown = useIsTabletDown();
  const [open, setOpen] = useState(false);

  const formattedDate = useMemo(
    () => (date ? `С ${dateToFormat({ date: date, format: HUMAN_DATE_FORMAT })}` : ""),
    [date],
  );

  const handleChange = (date?: string) => {
    if (!date) {
      return;
    }

    onChangeDate(date);
  };

  return (
    <>
      <SelectDateBase
        open={open}
        date={date}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        pickerProps={{
          minDate: getTodayDateTime(),
          maxDate: getTodayDateTime().plus({ year: 50 }),
        }}
        popupProps={{
          anchorOrigin: {
            vertical: "center",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "center",
            horizontal: "left",
          },
        }}
      >
        {(popupProps) => (
          <SelectHeader
            {...props}
            open={open}
            disabled={disabled}
            header={
              formattedDate ? (
                <Typography variant="input2" color={theme.palette.total.main}>
                  {formattedDate}
                </Typography>
              ) : undefined
            }
            onClick={(event) => {
              if (disabled) {
                return;
              }
              setOpen(true);

              if (!isSmallDown) {
                popupProps.onClick(event);
              }
            }}
          />
        )}
      </SelectDateBase>
    </>
  );
}
