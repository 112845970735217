import { createPortal } from "react-dom";
import React, { ReactNode, ReactElement, JSXElementConstructor } from "react";

interface Props {
  readonly children: ReactNode | ReactElement<any, string | JSXElementConstructor<any>>;
}

const bodyElement = document.querySelector("body");
const modalRootElement = document.querySelector("#modal-root");

export class ModalBase extends React.Component<Props> {
  private readonly element: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.element = document.createElement("div");

    this.element.setAttribute(
      "style",
      "position:fixed;top:0;left:0;right:0;bottom:0;display:flex;flex-direction:column;z-index:1100",
    );
  }

  componentDidMount() {
    if (modalRootElement) {
      modalRootElement.appendChild(this.element);
    }

    if (bodyElement) {
      setTimeout(() => {
        bodyElement.setAttribute("style", "overflow:hidden");
      }, 300);
    }
  }

  componentWillUnmount() {
    if (modalRootElement) {
      modalRootElement.removeChild(this.element);
    }

    if (bodyElement) {
      bodyElement.setAttribute("style", "overflow:unset");
    }
  }

  render() {
    // @ts-ignore
    return createPortal(this.props.children, this.element);
  }
}
