import { Stack, Typography } from "@mui/material";
import { isString, merge } from "lodash";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { SignStatus } from "../../dto/FundsDTO";
import { SignState } from "../../helpers/ManageFundsHelpers";
import { useTheme } from "../../theme/Theme";
import { SuccessCheckmarkIcon } from "../icons/SuccessCheckmarkIcon";
import { ErrorIcon } from "../icons/ErrorIcon";
import { HourglassIcon } from "../icons/HourglassIcon";
import { ActionButton, ButtonType } from "../ui/ActionButton";

export interface SignStatusObject {
  readonly title?: string;
  readonly content: ReactElement | string;
}

export interface StatusContent {
  [SignStatus.InProcess]?: SignStatusObject;
  [SignStatus.Success]?: SignStatusObject;
  [SignStatus.Fail]?: SignStatusObject;
}

interface SignTitle {
  readonly title: string;
  readonly content: string;
  readonly icon?: ReactElement;
}

function useGetStatusContent(signState: SignState, signStatusContent?: StatusContent) {
  const theme = useTheme();

  const signTitle: Record<SignStatus, SignTitle> = merge(
    {
      [SignStatus.InProcess]: {
        title: "Ожидание подписания",
        icon: <HourglassIcon />,
        content:
          "Пожалуйста, не покидайте экран и не закрывайте страницу, документ в процессе подписания.",
      },
      [SignStatus.Success]: {
        title: "Документ подписан",
        icon: <SuccessCheckmarkIcon color={theme.palette.grape.main} />,
        content: "Документ успешно подписан",
      },
      [SignStatus.Fail]: {
        title: "Ошибка",
        icon: <ErrorIcon />,
        content: "Что-то пошло не так, попробуйте повторить операцию ещё раз.",
      },
    },
    signStatusContent ?? {},
  );

  return signTitle[signState];
}

interface Props {
  readonly status: SignStatus;
  readonly statusContent?: StatusContent;
  actionsFails?: () => void;
  error?: string;
}

export function ManageFundsCheckStatus({
  status,
  statusContent: _statusContent,
  actionsFails,
  error,
}: Props) {
  const statusContent = useGetStatusContent(status, _statusContent);
  const theme = useTheme();
  const navigate = useNavigate();

  const { content } = statusContent;

  const actions = () => {
    if (status === SignStatus.Fail && actionsFails) {
      actionsFails();
      return;
    }
    if (status !== SignStatus.InProcess) {
      navigate(-1);
      return;
    }
  };
  const isCustomFail = status === SignStatus.Fail && actionsFails;
  const titleActionFail = isCustomFail ? "Повторить" : "На главную";
  const mtFail = isCustomFail ? 40 : 20;

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1} alignItems="center" mb={theme.typography.pxToRem(15)}>
        <Typography variant="h1" color={theme.palette.total.main}>
          {statusContent.title}
        </Typography>
        {statusContent.icon}
      </Stack>
      {isString(content) ? (
        <Typography variant="text2" color={theme.palette.gary.main}>
          {error || content}
        </Typography>
      ) : (
        content
      )}

      {status !== SignStatus.InProcess && (
        <ActionButton
          sx={{
            maxWidth: theme.typography.pxToRem(310),
            width: "100%",
            marginTop: theme.typography.pxToRem(mtFail),
          }}
          buttonType={ButtonType.Primary}
          onClick={actions}
        >
          <Typography variant={"calendar"}>{titleActionFail}</Typography>
        </ActionButton>
      )}
    </Stack>
  );
}
