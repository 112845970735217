import { createApi } from "@reduxjs/toolkit/query/react";

import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";
import { createUrl } from "../utils";

enum StrategyApiTypes {
  StrategyPromoList = "strategy-promo-list",
  PersonalData = "personal-data",
}

export const strategyApi = createApi({
  reducerPath: "strategyApi",
  baseQuery: fetchQuery(),
  tagTypes: [StrategyApiTypes.StrategyPromoList, StrategyApiTypes.PersonalData],
  endpoints: (builder) => ({
    strategyPromoList: builder.query<
      Application.ResponseProps<Strategy.StrategyPromoProps[]>,
      void
    >({
      query: () => ApiRoutes.StrategyPromoList,
      providesTags: [StrategyApiTypes.StrategyPromoList],
    }),
    strategyAgreementList: builder.query<
      Application.ResponseProps<Strategy.AgreementContractBuyStrategy>,
      Strategy.RequestGetAgreements
    >({
      query: (data) => createUrl(ApiRoutes.StrategyGetAgreement, { query: data }),
    }),
    strategyInterested: builder.mutation<void, { strategyId: string }>({
      query: (arg) => createUrl(ApiRoutes.StrategyInterested, { query: arg }),
      invalidatesTags: [StrategyApiTypes.StrategyPromoList],
    }),
    generateSmsBuyPromoStrategy: builder.mutation<void, { actionId: string }>({
      query: (data) => createUrl(ApiRoutes.StrategyGenerateSmsPromoStrategy, { query: data }),
    }),
    confirmSmsBuyPromoStrategy: builder.mutation<void, { actionId: string; code: string }>({
      query: (data) => createUrl(ApiRoutes.StrategySignSmsPromoStrategy, { query: data }),
      invalidatesTags: [StrategyApiTypes.PersonalData],
    }),
    getErrorByStrategy: builder.query<Application.ResponseProps<{ idStrategy?: string }>, void>({
      query: () => ApiRoutes.StrategyGetErrorByBuyStrategy,
    }),
    getDataWithErrorByStrategy: builder.query<
      Application.ResponseProps<Strategy.ErrorPersonalData>,
      void
    >({
      query: () => ApiRoutes.StrategyGetPersonalDataWithError,
      providesTags: [StrategyApiTypes.PersonalData],
    }),
    saveDataWithErrorByStrategy: builder.mutation<
      Application.ResponseProps<{
        actionId: string;
      }>,
      Strategy.SaveFixPersonalData
    >({
      query: (data) => ({
        url: ApiRoutes.StrategySavePersonalDataWithFix,
        body: data,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useStrategyInterestedMutation,
  useConfirmSmsBuyPromoStrategyMutation,
  useStrategyPromoListQuery,
  useSaveDataWithErrorByStrategyMutation,
  useLazyStrategyAgreementListQuery,
  useGenerateSmsBuyPromoStrategyMutation,
  useStrategyAgreementListQuery,
  useGetErrorByStrategyQuery,
  useLazyGetErrorByStrategyQuery,
  useGetDataWithErrorByStrategyQuery,
} = strategyApi;
