import React, { PropsWithChildren } from "react";
import { Snackbar } from "../ui/Snackbar";
import { useSelector } from "react-redux";
import { notificationSelector } from "../../slices/notificationSlice";
import { closeSnackbar, openSnackbar } from "../../utils/NotificationsUtils";

export function NotificationProvider(props: PropsWithChildren<unknown>) {
  const notificationState = useSelector(notificationSelector);

  return (
    <>
      {props.children}
      <Snackbar
        open={notificationState.open}
        title={notificationState.title}
        content={notificationState.content}
        openSnackbar={openSnackbar}
        closeSnackbar={closeSnackbar}
        variant="info"
      />
    </>
  );
}
