export enum SignStatus {
  Fail = "fail",
  Success = "success",
  InProcess = "in-process",
}

export enum SignPhase {
  SubmitCode = "submit-code",
  SubmitGoogleAuthCode = "confirm-fa",
}

export enum PeriodValue {
  Monthly = "month",
  Quarter = "quarter",
  HalfYear = "half-year",
  Year = "year",
}

export enum Period {
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
  Range = "range",
  HalfYear = "half-year",
}
