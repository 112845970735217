import { usePopupState } from "material-ui-popup-state/hooks";
import { ControlAriaProps } from "material-ui-popup-state/core";
import React, { MouseEvent, ReactNode, TouchEvent } from "react";
import { bindPopover, bindTrigger } from "material-ui-popup-state";

import { PopupButtonPopover, PopupButtonPopoverProps } from "./PopupButtonPopover";
import { PopupState } from "material-ui-popup-state/es/core";
import { isFunction, omit } from "lodash";

type ChildrenFunctionProps = ControlAriaProps & {
  onClick: (event: MouseEvent<any>) => void;
  onTouchStart: (event: TouchEvent<any>) => void;
};
type ContentFunctionType = (state: PopupState) => ReactNode;
type ChildrenFunctionType = (props: ChildrenFunctionProps) => ReactNode;

export interface PopupButtonProps
  extends Pick<
    PopupButtonPopoverProps,
    | "className"
    | "contentClassName"
    | "anchorOrigin"
    | "anchorPosition"
    | "transformOrigin"
    | "withArrow"
    | "PaperProps"
  > {
  readonly popupId: string;
  readonly children: ChildrenFunctionType;
  readonly content: ReactNode | ContentFunctionType;
  readonly onClose?: () => void;
}

export function PopupButton({ popupId, children, content, onClose, ...props }: PopupButtonProps) {
  const popupState = usePopupState({ variant: "popover", popupId });
  const boundProps = bindPopover(popupState);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    boundProps.onClose();
  };

  return (
    <>
      {children(bindTrigger(popupState))}

      <PopupButtonPopover
        {...props}
        content={isFunction(content) ? content(popupState) : content}
        onClose={handleClose}
        {...omit(boundProps, "onClose")}
      />
    </>
  );
}
