import { isString } from "lodash";
import { isValidElement, ReactNode } from "react";
import { IconButton, Stack, StackProps, Typography } from "@mui/material";

import { useTheme } from "../../theme/Theme";
import { CloseIcon } from "../icons/CloseIcon";
import { Variant } from "@mui/material/styles";

export interface SidebarHeaderProps extends Omit<StackProps, "children" | "title"> {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  onCloseClick?: () => void;
  variantTitle?: Variant;
}
export function SidebarHeader({
  title,
  subTitle,
  onCloseClick,
  variantTitle,
  ...props
}: SidebarHeaderProps) {
  const theme = useTheme();

  return (
    <Stack spacing={2} {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack direction="row" alignItems="center">
          {isString(title) ? (
            <Typography variant={variantTitle ?? "h1"} color={theme.palette.total.main}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Stack>
        {onCloseClick != null && (
          <IconButton sx={{ p: 0, ml: "32px" }} onClick={onCloseClick}>
            <CloseIcon size={24} />
          </IconButton>
        )}
      </Stack>
      {isString(subTitle) && (
        <Typography
          variant="text2"
          color={theme.palette.total.main}
          sx={{ whiteSpace: "break-spaces" }}
        >
          {subTitle}
        </Typography>
      )}
      {isValidElement(subTitle) && subTitle}
    </Stack>
  );
}
