import { AuthForm, AuthFormProps } from "./AuthForm";
import React from "react";
import { SaveDataByStep } from "./SaveDataByStep";
import { useRegistration } from "../../hooks/useRegistration";
import { AuthSkeleton } from "./AuthSkeleton";

export function AuthSidebar(props: AuthFormProps) {
  const { stepRegistration } = useRegistration();
  if (stepRegistration === 0) {
    return (
      <AuthSkeleton
        title={"Вход или регистрация в личном кабинете"}
        content={<AuthForm {...props} />}
      />
    );
  }

  return (
    <AuthSkeleton
      isShowTitle={false}
      content={<SaveDataByStep resetPhone={() => props.setPhone(undefined)} />}
    />
  );
}
