import cx from "classnames";
import { ButtonBaseProps } from "@mui/material";
import { MouseEvent, ReactNode, TouchEvent } from "react";
import { ControlAriaProps } from "material-ui-popup-state/core";

import { DatePickerBackdrop } from "./DatePickerBackdrop";
import { DatePickerContainer } from "./DatePickerContainer";
import { DatePickerProps, DatePickerType } from "./DatePicker";
import { makeStyles, useIsTabletUp, useTheme } from "../../theme/Theme";
import { PopupButton, PopupButtonProps } from "../info-button/PopupButton";

const useStyles = makeStyles((theme) => ({
  popover: { maxWidth: theme.typography.pxToRem(364) },
  popoverContent: { borderRadius: theme.typography.pxToRem(32) },
}));

interface ChildrenFunctionProps extends ControlAriaProps {
  readonly onClick: (event: MouseEvent<any>) => void;
  readonly onTouchStart: (event: TouchEvent<any>) => void;
}

type ChildrenFunctionType = (props: ChildrenFunctionProps) => ReactNode;

interface Props extends Omit<ButtonBaseProps, "children" | "role" | "type" | "onChange"> {
  readonly pickerType?: DatePickerType;
  readonly pickerProps: Omit<DatePickerProps, "value" | "onChange">;
  readonly onChange?: (date?: string) => void;
  readonly children: ChildrenFunctionType;
  /* Состояние открытия мобильного бэкдропа */
  readonly open: boolean;
  readonly onClose: () => void;
  readonly date?: string | null;
  readonly popupProps?: Omit<PopupButtonProps, "children" | "content" | "onClose" | "popupId">;
}

export function SelectDateBase({
  open,
  date,
  onClose,
  onChange,
  children,
  popupProps,
  pickerProps,
}: Props) {
  const theme = useTheme();
  const classes = useStyles();

  const isSmallUp = useIsTabletUp();
  const dateValue = date ? [date] : null;

  return (
    <>
      <PopupButton
        withArrow={false}
        popupId="calendar-button-popup"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...popupProps}
        onClose={onClose}
        className={cx(classes.popover, popupProps?.className)}
        contentClassName={cx(classes.popoverContent, popupProps?.contentClassName)}
        content={(popupState) => (
          <DatePickerContainer
            pb={theme.typography.pxToRem(20)}
            {...pickerProps}
            value={dateValue}
            type={DatePickerType.Default}
            onCloseClick={() => {
              onClose();
              popupState.close();
            }}
            onChange={(date) => {
              if (onChange) {
                onChange(date);
              }

              onClose();
              popupState.close();
            }}
          />
        )}
      >
        {children}
      </PopupButton>

      <DatePickerBackdrop
        onRequestClose={onClose}
        show={!isSmallUp && open}
        pickerProps={{
          ...pickerProps,
          onChange: (date) => {
            onChange?.(date);
          },
          value: dateValue,
        }}
      />
    </>
  );
}
