import { AlertColor, Box, BoxProps, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTheme } from "../../theme/Theme";
import { WarningIcon } from "../icons/WarningIcon";

interface Props extends BoxProps {
  readonly text: string;
  readonly variant?: AlertColor;
  readonly rightElement?: ReactElement;
}

export function Alert({ text, rightElement, variant = "info", ...props }: Props) {
  const theme = useTheme();
  return (
    <Box
      p={theme.typography.pxToRem(16)}
      borderRadius={theme.typography.pxToRem(16)}
      border={`1px solid ${theme.palette.sun.main}`}
      {...props}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" flexGrow={1} flexShrink={1}>
          <WarningIcon />
          <Typography
            variant="text4"
            sx={{ whiteSpace: "pre-line" }}
            color={theme.palette.cow.main}
            ml={theme.typography.pxToRem(12)}
          >
            {text}
          </Typography>
        </Stack>
        {rightElement}
      </Stack>
    </Box>
  );
}
