import { ButtonBase, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "../../theme/Theme";
import classNames from "classnames";
interface Props {
  value: Application.OptionSelect;
  onClick: (value: string | number) => void;
  isSelect: boolean;
  mb?: number;
}
interface StylesProps {
  mb: number;
}
const useStyles = makeStyles<StylesProps>((theme) => ({
  cell: ({ mb }) => ({
    flex: 1,
    textAlign: "center",
    width: theme.pxToRem(100),
    height: theme.pxToRem(36),
    borderRadius: theme.pxToRem(16) + "!important",
    color: theme.palette.total.main,
    transition: "all 0.3s",
    marginBottom: theme.pxToRem(mb) + "!important",
    marginTop: theme.pxToRem(mb) + "!important",

    "&:hover": {
      backgroundColor: theme.palette.back40.main + "!important",
    },
  }),
  cell_active: {
    backgroundColor: theme.palette.bloom.main + "!important",
    color: theme.palette.const.main + "!important",

    "&:hover": {
      backgroundColor: theme.palette.bloom.main + "!important",
    },
  },
}));

export function DatePickerCellSelect({
  onClick,
  value: { value, label },
  mb = 0,
  isSelect,
}: Props) {
  const styles = useStyles({ mb });
  return (
    <Grid item={true} display={"flex"} flex={1}>
      <ButtonBase
        className={classNames(styles.cell, {
          [styles.cell_active]: isSelect,
        })}
        onClick={() => onClick(value)}
      >
        <Typography variant="calendar">{label}</Typography>
      </ButtonBase>
    </Grid>
  );
}
