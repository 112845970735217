import React from "react";
import { useColor } from "../../hooks/useColor";

export function HourglassIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.grape.main });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.08752 8.56209L7.44169 8.20916L7.08752 8.56209L9.01576 10.497C9.75705 11.2409 9.75705 12.4477 9.01576 13.1916L7.08752 15.1265L7.44169 15.4795L7.08752 15.1265C6.84416 15.3707 6.70775 15.7016 6.70775 16.0462V19.8592C6.70775 21.019 7.64515 21.9621 8.80516 21.9621H15.1948C16.3548 21.9621 17.2922 21.019 17.2922 19.8592V16.0462C17.2922 15.7016 17.1558 15.3707 16.9124 15.1265L14.9842 13.1916C14.2429 12.4477 14.2429 11.2409 14.9842 10.497L16.9124 8.56209C17.1558 8.31788 17.2922 7.98704 17.2922 7.64242V3.82947C17.2922 2.66968 16.3548 1.7265 15.1948 1.7265H8.80516C7.64515 1.7265 6.70775 2.66968 6.70775 3.82947V7.64242C6.70775 7.98704 6.84416 8.31788 7.08752 8.56209ZM9.30516 19.0577C9.30516 18.8895 9.44053 18.7562 9.60386 18.7562H14.3961C14.5594 18.7562 14.6948 18.8895 14.6948 19.0577C14.6948 19.2258 14.5594 19.3592 14.3961 19.3592H9.60386C9.44053 19.3592 9.30516 19.2258 9.30516 19.0577ZM8.80516 1.12354H15.1948C16.6814 1.12354 17.8896 2.33338 17.8896 3.82947V7.64242C17.8896 8.14807 17.6894 8.63267 17.3336 8.98969L15.4054 10.9246C14.899 11.4328 14.899 12.2559 15.4054 12.764L17.3336 14.6989C17.6894 15.056 17.8896 15.5406 17.8896 16.0462V19.8592C17.8896 21.3552 16.6814 22.5651 15.1948 22.5651H8.80516C7.3185 22.5651 6.11035 21.3552 6.11035 19.8592V16.0462C6.11035 15.5406 6.31054 15.056 6.66632 14.6989L8.59456 12.764C9.10091 12.2559 9.10091 11.4328 8.59456 10.9246L6.66632 8.98969C6.31054 8.63266 6.11035 8.14807 6.11035 7.64242V3.82947C6.11035 2.33338 7.3185 1.12354 8.80516 1.12354Z"
        fill={iconColor}
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
