import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { DatePickerSelect } from "./DatePickerSelect";
import { useTheme } from "../../theme/Theme";
import { formatMonthTitle } from "../../helpers/DateHelpers";
interface Props {
  readonly month?: number;
  readonly onChangeMonth: (year: number) => void;
}
export function DatePickerSelectMonth({ onChangeMonth, month }: Props) {
  const theme = useTheme();
  const monthsForSelect = useMemo(() => {
    const years: Application.OptionSelect[] = [];
    for (let i = 1; i <= 12; i++) {
      years.push({
        label: formatMonthTitle(i),
        value: i,
      });
    }
    return years;
  }, []);

  return (
    <Box
      overflow={"hidden"}
      sx={{
        maxHeight: theme.pxToRem(340),

        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <DatePickerSelect
        mb={25}
        selectValue={month}
        onChange={(monthSelect) => onChangeMonth(+monthSelect)}
        dataForSelect={monthsForSelect}
      />
    </Box>
  );
}
