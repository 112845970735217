import React, { ChangeEvent, useEffect, useState } from "react";

import { TextInput, TextInputProps } from "./TextInput";
import { maskPhoneNumber } from "../../utils/FormatUtils";

export interface PhoneInputProps extends TextInputProps {
  readonly onChangeText?: (value: string) => void;
}

export function PhoneInput({ value, onChange, onChangeText, ...props }: PhoneInputProps) {
  const [formattedValue, setFormattedValue] = useState(value);

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onChangeText) {
      onChangeText(maskPhoneNumber(event.target.value));
    }

    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setFormattedValue(maskPhoneNumber(value));
  }, [value]);

  return <TextInput {...props} type="tel" onChange={changeHandler} value={formattedValue} />;
}
