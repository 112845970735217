import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl, maskPhoneNumber } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";
import { HttpMethod } from "../dto/ApplicationDTO";
import { omit } from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { hidePhoneNumbers } from "../helpers/FormatHelpers";
import { RootState } from "../store/configureStore";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchQuery(),
  keepUnusedDataFor: 30,
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    profile: builder.query<Application.ResponseProps<Profile.Props>, void>({
      query: () => ApiRoutes.ProfileMe,
      providesTags: ["Profile"],
    }),
    ndflReference: builder.query<Application.ResponseProps<Profile.NdflReferenceProps>, void>({
      query: () => ApiRoutes.ProfileNdflReference,
    }),

    /* Update avatar */
    changeProfileAvatar: builder.mutation<void, Common.ImageBodyInput>({
      invalidatesTags: ["Profile"],
      query: (body) => ({
        body,
        method: HttpMethod.Patch,
        url: ApiRoutes.ProfileAvatar,
      }),
    }),
    deleteProfileAvatar: builder.mutation<void, void>({
      invalidatesTags: ["Profile"],
      query: () => ({ method: HttpMethod.Delete, url: ApiRoutes.ProfileAvatar }),
    }),

    /* Update documents */
    updatePassport: builder.mutation<void, FormData>({
      query: (body) => ({
        body,
        method: HttpMethod.Post,
        url: ApiRoutes.ProfilePassport,
      }),
    }),
    editTaxStatus: builder.mutation<void, Common.FilesBodyInput>({
      query: (body) => ({
        url: ApiRoutes.ProfileTaxStatus,
        method: HttpMethod.Post,
        body: body,
      }),
    }),

    /* Phone editing */
    changePhone: builder.mutation<Application.ResponseProps<void>, Profile.ChangePhone>({
      query: (query) => createUrl(ApiRoutes.ProfilePhoneChange, { query }),
    }),
    phoneSendCode: builder.mutation<Application.ResponseProps<void>, Profile.SendCodeOnPhone>({
      query: (query) => createUrl(ApiRoutes.ProfilePhoneSendCode, { query }),
    }),
    phoneCheckCode: builder.mutation<
      Application.ResponseProps<Profile.CheckCodeProps>,
      Profile.CheckCodePhone
    >({
      query: (query) => createUrl(ApiRoutes.ProfilePhoneCheckCode, { query }),
    }),

    /* Email editing */
    changeEmail: builder.mutation<Application.ResponseProps<void>, Profile.ChangeEmailInput>({
      query: (query) => createUrl(ApiRoutes.ProfileEmailChange, { query }),
    }),
    emailSendCode: builder.mutation<Application.ResponseProps<void>, Profile.SendCodeInput>({
      query: (query) => createUrl(ApiRoutes.ProfileEmailSendCode, { query }),
    }),
    emailCheckCode: builder.mutation<
      Application.ResponseProps<Profile.CheckCodeProps>,
      Profile.CheckCodeInput
    >({
      query: (query) => createUrl(ApiRoutes.ProfileEmailCheckCode, { query }),
    }),

    /* Requisites */
    getRequisiteList: builder.query<Application.ResponseProps<Profile.RequisiteShortProps[]>, void>(
      {
        query: (query) => createUrl(ApiRoutes.ManageFundsRequisiteList, { query }),
      },
    ),
    getRequisite: builder.query<
      Application.ResponseProps<Profile.RequisiteProps>,
      Profile.RequisiteInput
    >({
      query: (query) => createUrl(ApiRoutes.ManageFundsRequisite, { query }),
    }),
    generateCodeForRequisite: builder.mutation<void, Profile.GenerateCodeForRequisitesInput>({
      query: (query) => createUrl(ApiRoutes.ManageFundsRequisiteGenerateCode, { query }),
    }),
    saveChangesRequisite: builder.mutation<
      Application.ResponseProps<Profile.RequisiteProps>,
      Profile.RequisiteSaveChangeInput
    >({
      query: (query) => ({
        url: createUrl(ApiRoutes.ManageFundsRequisite, { query: omit(query, "body") }),
        method: HttpMethod.Patch,
        body: query.body,
      }),
    }),
  }),
});

export const {
  /* Requisites */
  useGetRequisiteListQuery,
  useGetRequisiteQuery,
  useLazyGetRequisiteQuery,
  useGenerateCodeForRequisiteMutation,
  useSaveChangesRequisiteMutation,

  /* Profile data */
  useProfileQuery,
  useLazyProfileQuery,
  useNdflReferenceQuery,

  /* Avatar */
  useChangeProfileAvatarMutation,
  useDeleteProfileAvatarMutation,

  /* Documents */
  useUpdatePassportMutation,
  useEditTaxStatusMutation,

  /* Phone editing */
  useChangePhoneMutation,
  usePhoneSendCodeMutation,
  usePhoneCheckCodeMutation,

  /* Email editing */
  useChangeEmailMutation,
  useEmailSendCodeMutation,
  useEmailCheckCodeMutation,
} = profileApi;

/**
 * Selectors
 */

export const selectProfileResult = profileApi.endpoints.profile.select();

interface ProfilePhoneSelectorProps {
  readonly hidden?: boolean;
  readonly masked?: boolean;
}

export function profilePhoneSelector(props?: ProfilePhoneSelectorProps) {
  return createSelector(selectProfileResult, (data) => {
    const profile = data?.data?.data;

    if (props?.masked) {
      const maskedPhone = maskPhoneNumber(profile?.phone);

      if (props?.hidden) {
        return hidePhoneNumbers(maskedPhone);
      }

      return maskedPhone;
    }

    return profile?.phone;
  });
}

export function profileSelector(state: RootState): Profile.Props | undefined {
  return createSelector(selectProfileResult, (profile) => profile?.data?.data)(state);
}

export const systemIsDemoModeSelector = (state: RootState) =>
  createSelector(
    selectProfileResult,
    (profile) => state.application.modeUser === "cold" || state.application.modeUser === "hot",
  )(state);
