import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { DatePickerSelect } from "./DatePickerSelect";
import { BaseTheme, makeStyles } from "../../theme/Theme";
import { toString } from "lodash";
import classNames from "classnames";
import useDate from "../../hooks/useDate";
interface Props {
  readonly minDate: DateTime;
  readonly maxDate?: DateTime;
  readonly year?: number;
  readonly onChangeYear: (year: number) => void;
}

const useStyles = makeStyles(({ pxToRem, palette }) => ({
  containerYears: {
    maxHeight: pxToRem(340),
    overflow: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: `${palette.grape.main} ${BaseTheme.palette?.common.transparent}`,
  },
}));
export function DatePickerSelectYear({ minDate: { year: MIN_YEAR }, onChangeYear, year }: Props) {
  const styles = useStyles();
  const { INITIAL_MAX_DATE } = useDate();
  const yarnsForSelect = useMemo(() => {
    const years: Application.OptionSelect[] = [];
    for (let i = MIN_YEAR; i <= INITIAL_MAX_DATE.year; i++) {
      years.push({
        label: toString(i),
        value: i,
      });
    }
    return years;
  }, [INITIAL_MAX_DATE.year, MIN_YEAR]);

  return (
    <Box overflow={"auto"} className={classNames(styles.containerYears, "scrollBarGrape")}>
      <DatePickerSelect
        selectValue={year}
        mb={8}
        onChange={(yearSelect) => onChangeYear(+yearSelect)}
        dataForSelect={yarnsForSelect}
      />
    </Box>
  );
}
