import { Box, BoxProps } from "@mui/material";
import cx from "classnames";
import { isString } from "lodash";
import React, { CSSProperties, ReactNode } from "react";

import { makeStyles } from "../../theme/Theme";
import { formatHexToRGBA } from "../../utils/FormatUtils";

interface StylesProps {
  readonly width?: number | string;
  readonly height: number | string;
  readonly radius: number;
  readonly backgroundColor?: string;
  readonly direction: "row" | "column";
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    position: "relative",
    flexDirection: (props) => props.direction,
    height: (props) =>
      isString(props.height) ? props.height : theme.typography.pxToRem(props.height),
    width: (props) =>
      isString(props.width)
        ? props.width
        : props.width
        ? theme.typography.pxToRem(props.width)
        : undefined,
    borderRadius: (props) => theme.typography.pxToRem(props.radius),
    backgroundColor: (props) =>
      props.backgroundColor || formatHexToRGBA({ color: theme.palette.gary.main, alpha: 0.2 }),
  },
  activity: {
    position: "absolute",
    left: "-45%",
    height: "100%",
    width: "45%",
    backgroundImage:
      "linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05))",
    animation: `$loading 1s ${theme.transitions.easing.easeIn} infinite`,
    zIndex: 2,
  },
  "@keyframes loading": {
    "0%": {
      left: "-45%",
    },
    "100%": {
      left: "100%",
    },
  },
}));

export interface PlaceholderBoxProps extends BoxProps {
  readonly width?: number | string;
  readonly height?: number | string;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly radius?: number;
  readonly backgroundColor?: string;
  readonly children?: ReactNode;
  readonly direction?: "row" | "column";

  mb?: number;
  mt?: number;
}

export function PlaceholderBox({
  width,
  style,
  height = 20,
  radius = 12,
  children,
  className,
  backgroundColor,
  direction = "column",
  mb = 0,
  mt = 0,
  ...other
}: PlaceholderBoxProps) {
  const classes = useStyles({ height, width, radius: radius, backgroundColor, direction });

  return (
    <Box
      {...other}
      style={style}
      mb={`${mb}px`}
      mt={`${mt}px`}
      className={cx(classes.root, className)}
    >
      <div className={classes.activity} />
      {children}
    </Box>
  );
}
