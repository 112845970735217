import React from "react";

import { makeStyles } from "../../theme/Theme";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

const useStyles = makeStyles({
  root: { flex: 1 },
});

interface Props {
  readonly size?: number;
  readonly minSize?: number;
  sx?: SxProps<Theme>;
}

export function Spacer({ size, minSize, sx = {} }: Props) {
  const classes = useStyles();

  const fixedStyles = {
    width: size,
    height: size,
    minWidth: minSize,
    minHeight: minSize,
  };

  return <Box sx={{ ...fixedStyles, ...sx }} className={!size ? classes.root : ""} />;
}
