import React from "react";

import { DatePickerProps } from "./DatePicker";
import { Backdrop, BackdropProps } from "../sidebar/Backdrop";
import { DatePickerContainer } from "./DatePickerContainer";

interface Props extends Omit<BackdropProps, "children"> {
  readonly pickerProps: DatePickerProps;
}

export function DatePickerBackdrop({ pickerProps, ...props }: Props) {
  return (
    <Backdrop {...props}>
      {({ onClose }) => (
        <DatePickerContainer
          {...pickerProps}
          onCloseClick={onClose}
          onChange={(date, date2) => {
            pickerProps.onChange(date, date2);

            onClose();
          }}
        />
      )}
    </Backdrop>
  );
}
