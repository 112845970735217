import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useTheme } from "../../theme/Theme";
import { SignState } from "../../helpers/ManageFundsHelpers";
import { SignPhase, SignStatus } from "../../dto/FundsDTO";
import { ManageFundsCheckStatus, StatusContent } from "./ManageFundsCheckStatus";
import { ManageFundsSubmitCode } from "./ManageFundsSubmitCode";
import {
  useCheckCodeFromSmsMutation,
  useGenerateOrderCodeMutation,
  useOrderSignMutation,
  useOrderSignStatusQuery,
} from "../../api/manageFundsApi";
import { ProfileSubmitCode } from "../profile/ProfileSubmitCode";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { settingsSelector } from "../../api/settingsApi";
import { openSnackbar, openSnackbarError } from "../../utils/NotificationsUtils";

interface Props {
  readonly orderId: string;
  readonly phoneNumber: string;
  readonly initialState?: SignState;
  enabledFA?: boolean;
  onClose?: () => void;
  reset?: () => void;
}

type CodeStateType = Omit<ManageFunds.OrderSignBodyProps, "orderId">;
export function ManageFundsSignOrder({
  orderId,
  phoneNumber,
  initialState = SignPhase.SubmitCode,
  reset,
  enabledFA,
  onClose,
}: Props) {
  const theme = useTheme();
  const settings = useShallowEqualSelector(settingsSelector);

  const [codeState, setCodeState] = useState<CodeStateType>({
    code: "",
    faCode: "",
  });
  const withTwoFactor =
    settings?.twoFactorAuth?.enabled != null && settings?.twoFactorAuth?.enabled;

  const signStatusContent: StatusContent = {
    [SignStatus.Success]: {
      title: initialState === SignPhase.SubmitCode ? "Поручение сформировано" : "Документ подписан",
      content: (
        <>
          <Typography variant="text2" color={theme.palette.gary.main}>
            {initialState === SignPhase.SubmitCode
              ? "Спасибо за заявку, поручение сформировано. В случае необходимости уточнения информации мы свяжемся с вами."
              : "Документ успешно подписан"}
          </Typography>
        </>
      ),
    },
  };

  const [signState, setSignState] = useState<SignState>(initialState);
  const [checkingStatus, setCheckingStatus] = useState<boolean>(false);

  const [orderSignMutation] = useOrderSignMutation();
  const [generateOrderCodeMutation] = useGenerateOrderCodeMutation();
  const [checkCodeFromSms] = useCheckCodeFromSmsMutation();
  const statusQuery = useOrderSignStatusQuery(
    { orderId: orderId },
    { pollingInterval: 3000, skip: !checkingStatus },
  );

  useEffect(() => {
    if (statusQuery.error) {
      openSnackbarError(statusQuery.error);
      setCheckingStatus(false);
      return;
    }

    if (!statusQuery.data || !statusQuery.data.data) {
      return;
    }

    if (statusQuery.data.data.status === "success" || statusQuery.data.data.status === "new") {
      setSignState(SignStatus.Success);
    }
    setCheckingStatus(false);
  }, [statusQuery, openSnackbar, openSnackbarError]);

  const signHandler = () =>
    generateOrderCodeMutation({ orderId })
      .unwrap()
      .then(() => setSignState(SignPhase.SubmitCode))
      .catch((e) => {
        openSnackbarError(e);
      });

  const submitCodeHandler = (code: CodeStateType) => {
    return checkCodeFromSms({
      code: code.code,
      orderId,
    })
      .unwrap()
      .then(() => {
        setCodeState(code);
        if (enabledFA && withTwoFactor && signState !== SignPhase.SubmitGoogleAuthCode) {
          setSignState(SignPhase.SubmitGoogleAuthCode);
        } else {
          getOrderIdOperation(code);
        }
      })
      .catch((e) => {
        openSnackbarError(e);
      });
  };

  const getOrderIdOperation = (code: CodeStateType) => {
    return orderSignMutation({
      code: code.code,
      orderId,
      faCode: code.faCode,
    })
      .unwrap()
      .then(() => {
        if (initialState === SignPhase.SubmitCode) {
          setSignState(SignStatus.Success);
        } else {
          setCheckingStatus(true);
        }
      })
      .catch(openSnackbarError);
  };

  useEffect(() => {
    if (initialState === SignPhase.SubmitCode) {
      signHandler();
    }
  }, []);

  return (
    <Paper sx={{ backgroundColor: theme.palette.walter.main }} elevation={1}>
      {signState === SignPhase.SubmitCode ? (
        <ManageFundsSubmitCode
          onClose={onClose}
          onResend={signHandler}
          onSubmit={(codeSms) => submitCodeHandler({ code: codeSms, faCode: codeState.faCode })}
          phoneNumber={phoneNumber}
        />
      ) : signState === SignPhase.SubmitGoogleAuthCode ? (
        <ProfileSubmitCode
          key="google"
          textVariant={"input2"}
          text="Введите код из Google Authenticator."
          title={"Введите код подтверждения"}
          onCloseClick={onClose}
          onSubmit={(faCode) => getOrderIdOperation({ faCode, code: codeState.code })}
        />
      ) : (
        <ManageFundsCheckStatus
          actionsFails={reset}
          statusContent={signStatusContent}
          status={signState}
        />
      )}
    </Paper>
  );
}
