import React from "react";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../theme/Theme";

const useStyles = makeStyles((theme) => ({
  text: {
    flex: 1,
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.grape.main,
    width: theme.typography.pxToRem(36),
  },
  root: {
    display: "flex",
    alignItems: "center",
    height: theme.typography.pxToRem(36),
  },
}));

export function DatePickerWeekDays() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="card-secondary" className={classes.text}>
        пн
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        вт
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        ср
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        чт
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        пт
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        сб
      </Typography>
      <Typography variant="card-secondary" className={classes.text}>
        вс
      </Typography>
    </div>
  );
}
