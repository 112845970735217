import { ManageFundsHeader } from "../manage-funds";
import Typography from "@mui/material/Typography";
import React from "react";
import { Stack } from "@mui/material";
import { useRegistration } from "../../hooks/useRegistration";
import { makeStyles } from "../../theme/Theme";
import { AuthSidebarConfirmEmail } from "./AuthSidebarConfirmEmail";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { ProgressStepSaveStepUser } from "./ProgressStepSaveStepUser";
const useStyles = makeStyles((theme) => ({
  wrapperBack: {
    marginBottom: 8,
  },
  root: {
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginTop: 14,
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginTop: 60,
    },
  },
}));
interface Props {
  resetPhone: () => void;
}

export const SaveDataByStep = ({ resetPhone }: Props) => {
  const styles = useStyles();
  const { prevStep, stepRegistration, resetData } = useRegistration();
  const onBack = () => {
    if (stepRegistration > 1) return prevStep();
    resetData();
    resetPhone();
  };
  return (
    <Stack className={styles.root}>
      <ManageFundsHeader classNamesProps={styles.wrapperBack} onBack={onBack}>
        <Typography variant="h1">Регистрация</Typography>
      </ManageFundsHeader>
      <ProgressStepSaveStepUser />
      <AuthSidebarConfirmEmail />
    </Stack>
  );
};
