import cx from "classnames";
import { PropsWithChildren } from "react";
import { Drawer, Box, Stack, StackProps } from "@mui/material";

import { makeStyles } from "../../theme/Theme";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiPaper-root": {
      padding: 0,
      width: theme.typography.pxToRem(646),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: theme.palette.walter.main,
      borderTopLeftRadius: theme.typography.pxToRem(40),
      borderBottomLeftRadius: theme.typography.pxToRem(40),
    },
  },
  stripe: {
    background: theme.palette.grape.main,
    borderTopLeftRadius: theme.typography.pxToRem(40),
    borderBottomLeftRadius: theme.typography.pxToRem(40),
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    width: theme.typography.pxToRem(120),
  },
  drawerContent: {
    paddingTop: theme.typography.pxToRem(150),
    paddingLeft: theme.typography.pxToRem(80),
    paddingRight: theme.typography.pxToRem(200),
  },
}));

export interface SidebarBaseProps {
  readonly open: boolean;
  readonly onExit?: () => void;
  readonly onClose?: () => void;
  readonly outsideClickClose?: boolean;
  readonly drawerContent?: Omit<StackProps, "children">;
}

export function SidebarBase({
  children,
  open,
  onClose,
  onExit,
  drawerContent,
  outsideClickClose = true,
}: PropsWithChildren<SidebarBaseProps>) {
  const styles = useStyles();

  return (
    <Drawer
      onTransitionEnd={onExit}
      anchor="right"
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      ModalProps={{
        onBackdropClick: () => {
          if (outsideClickClose && onClose != null) {
            onClose();
          }
        },
      }}
      PaperProps={{
        elevation: 0,
      }}
      className={styles.drawer}
    >
      <Stack
        direction="column"
        {...drawerContent}
        className={cx(styles.drawerContent, drawerContent?.className)}
      >
        {children}
      </Stack>

      <Box className={styles.stripe} />
    </Drawer>
  );
}
