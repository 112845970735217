import React from "react";
import { useColor } from "../../hooks/useColor";

export function SuccessCheckmarkIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || "white" });

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 14L8.23309 16.4248C8.66178 16.7463 9.26772 16.6728 9.60705 16.2581L18 6"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
