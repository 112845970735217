import { makeStyles } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import React, { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import { AuthFormHeader } from "./AuthFormHeader";
import { noop } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.flexColumn,
    backgroundColor: theme.palette.wall.main,
    [theme.breakpoints.only(Breakpoints.Desktop)]: {
      width: 550,
    },
    [theme.breakpoints.only(Breakpoints.Laptop)]: {
      width: 415,
    },
    [theme.breakpoints.only(Breakpoints.Tablet)]: {
      width: 355,
    },
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      width: "100%",
    },
  },
  title: {
    [theme.breakpoints.only(Breakpoints.Desktop)]: {
      marginTop: 100,
      marginLeft: 50,
      marginBottom: 32,
      marginRight: 190,
    },
    [theme.breakpoints.only(Breakpoints.Laptop)]: {
      marginTop: 80,
      marginLeft: 40,
      marginRight: 65,
      marginBottom: 32,
    },
    [theme.breakpoints.only(Breakpoints.Tablet)]: {
      marginTop: 60,
      marginRight: 65,
      marginLeft: 30,
      marginBottom: 20,
    },
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      marginTop: 20,
      marginBottom: 30,
      marginRight: 25,
      marginLeft: 25,
    },
  },
  wrapperroot: {
    ...theme.mixins.flexColumn,

    [theme.breakpoints.only(Breakpoints.Desktop)]: {
      width: 310 + 50,
    },
    [theme.breakpoints.only(Breakpoints.Laptop)]: {
      width: 255 + 40,
    },
    [theme.breakpoints.only(Breakpoints.Tablet)]: {
      width: 295 + 30,
    },
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      width: "100%",
    },
  },
  wrapperContent: {
    [theme.breakpoints.only(Breakpoints.Desktop)]: {
      marginLeft: 50,
    },
    [theme.breakpoints.only(Breakpoints.Laptop)]: {
      marginLeft: 40,
    },
    [theme.breakpoints.only(Breakpoints.Tablet)]: {
      marginLeft: 30,
    },
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      marginRight: 25,
      marginLeft: 25,
      marginBottom: 14,
    },
  },
}));

interface Props {
  content: ReactNode;
  title?: string;
  isShowTitle?: boolean;
}
export const AuthSkeleton = ({ content, isShowTitle = true, title }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isShowTitle && (
        <Stack className={classes.title}>
          <AuthFormHeader title={title || ""} onBackClick={noop} />
        </Stack>
      )}
      <Stack className={classes.wrapperroot}>
        <Stack className={classes.wrapperContent}>{content}</Stack>
      </Stack>
    </div>
  );
};
