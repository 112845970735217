import Typography from "@mui/material/Typography";
import { memo } from "react";

import { PeriodValue } from "../../dto/FundsDTO";
import { useTheme } from "../../theme/Theme";
import { dateToFormat, SHORT_URL_DATE_FORMAT } from "../../utils";
import { Select } from "../ui/select/Select";
import { OptionValue, SelectOption } from "../ui/select/SelectOption";
import { ManageFundsSelectDate } from "./ManageFundsSelectDate";
import { WrapperWithTitle } from "../ui/WrapperWithTitle";
import { Spacer } from "../ui/Spacer";

interface Props {
  readonly disabled?: boolean;
  readonly onPeriodChange: (period: ManageFunds.Period) => void;
  readonly period?: ManageFunds.Period | null;
  readonly date?: string | null;
  readonly onDateChange: (date: string) => void;
  readonly expandedInitial?: boolean;
}

export const periodOptions: ManageFunds.Period[] = [
  {
    label: "Ежемесячно",
    value: PeriodValue.Monthly,
  },
  {
    label: "Раз в квартал",
    value: PeriodValue.Quarter,
  },
  {
    label: "Раз в полгода",
    value: PeriodValue.HalfYear,
  },
  {
    label: "Раз в год",
    value: PeriodValue.Year,
  },
];

export const ManageFundsRecurrentPayment = memo<Props>(
  ({ disabled, onPeriodChange, period, date, onDateChange, expandedInitial }) => {
    const theme = useTheme();

    const periodChangeHandler = (value: OptionValue) => {
      onPeriodChange(periodOptions[value as number]);
    };

    const dateChangeHandler = (date: string) => {
      onDateChange(dateToFormat({ date, format: SHORT_URL_DATE_FORMAT }));
    };

    return (
      <div>
        <WrapperWithTitle disabled={disabled} topTitle={"Период"}>
          <Select
            disabled={disabled}
            bottomGutterHeight={0}
            placeholder="Выберите период"
            onSelect={periodChangeHandler}
            label={
              period && (
                <Typography color={theme.palette.cow.main} variant="input2">
                  {period.label}
                </Typography>
              )
            }
          >
            {periodOptions.map((option, index) => {
              return (
                <SelectOption key={`strategy-option-${index}`} value={index} title={option.label} />
              );
            })}
          </Select>
        </WrapperWithTitle>
        <Spacer size={20} />
        <WrapperWithTitle disabled={disabled} topTitle={"Дата первого платежа"}>
          <ManageFundsSelectDate
            date={date}
            onChangeDate={dateChangeHandler}
            disabled={disabled ?? false}
          />
        </WrapperWithTitle>
        <Spacer size={20} />
      </div>
    );
  },
);
