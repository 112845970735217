import cx from "classnames";
import React from "react";

import { makeStyles, useTheme } from "../../theme/Theme";
import { ChevronIcon } from "../icons/ChevronIcon";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiButtonBase-root": {
      display: "flex",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      width: theme.typography.pxToRem(38),
      height: theme.typography.pxToRem(38),
      borderRadius: theme.typography.pxToRem(19),
      backgroundColor: theme.palette.grape.main,
    },
  },
  icon: {
    marginRight: theme.typography.pxToRem(2),
  },
}));

export function RoundBackButton({ className, ...props }: Omit<ButtonBaseProps, "children">) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ButtonBase {...props} className={cx(classes.root, className)}>
      <ChevronIcon className={classes.icon} color={theme.palette.const.main} />
    </ButtonBase>
  );
}
