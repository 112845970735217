import { MouseEvent, useMemo } from "react";
import { DateTime } from "luxon";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../theme/Theme";
import { ChevronIcon } from "../icons/ChevronIcon";
import { Direction } from "../../dto/ApplicationDTO";
import { pressAreaStyles } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: { height: theme.typography.pxToRem(44), display: "flex" },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.typography.pxToRem(40),
  },
  year: {
    ...pressAreaStyles,
    "&.MuiTypography-root": {
      marginLeft: theme.typography.pxToRem(6),
    },
  },
  month: {
    ...pressAreaStyles,
    textTransform: "capitalize",
    "&.MuiTypography-root": {
      marginRight: theme.typography.pxToRem(6),
    },
  },
  titleContainer: {
    ...pressAreaStyles,
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type SelectType = Extract<Common.PeriodType, "day" | "year" | "month">;

interface Props extends Application.IntervalDateType {
  date: DateTime;
  showTypeSelect: Common.PeriodType;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  handlerYearSelect: () => void;
  resetShow: () => void;
}

export function DatePickerMonths({
  date,
  minDate,
  showTypeSelect,
  maxDate,
  onNextMonthClick,
  onPrevMonthClick,
  handlerYearSelect,
  resetShow,
}: Props) {
  const classes = useStyles();
  const typeSelectForArrow: SelectType = showTypeSelect as SelectType;
  const hidePrevButton = useMemo(() => {
    if (showTypeSelect !== "day") return true;
    if (minDate != null) {
      const monthMore = date.month <= minDate.month;
      const yearMore = date.year <= minDate.year;

      return monthMore && yearMore;
    }

    return false;
  }, [minDate, showTypeSelect, date]);

  const hideNextButton = useMemo(() => {
    if (showTypeSelect !== "day") return true;

    if (maxDate != null) {
      const monthLess = date.month >= maxDate.month;
      const yearLess = date.year >= maxDate.year;

      return monthLess && yearLess;
    }

    return false;
  }, [maxDate, showTypeSelect, date]);

  const getDirectionArrow = (typeSelect: SelectType): Direction => {
    const directionArrowBySelect: Record<SelectType, Direction> = {
      day: Direction.Down,
      year: Direction.Up,
      month: Direction.Down,
    };
    return directionArrowBySelect[typeSelect] || Direction.Down;
  };

  return (
    <Box className={classes.root}>
      <div className={classes.buttonContainer}>
        {!hidePrevButton && (
          <IconButton onClick={onPrevMonthClick}>
            <ChevronIcon />
          </IconButton>
        )}
      </div>

      <div onClick={handlerYearSelect} className={classes.titleContainer}>
        <Typography variant="calendar-month" className={classes.month}>
          {date.monthLong}
        </Typography>
        <Typography variant="calendar-month" className={classes.year}>
          {date.year}
        </Typography>
        <IconButton
          onClick={(e) => {
            const event = e as MouseEvent;
            event.stopPropagation();
            if (showTypeSelect !== "day") {
              resetShow();
            } else handlerYearSelect();
          }}
        >
          <ChevronIcon withTransition={true} direction={getDirectionArrow(typeSelectForArrow)} />
        </IconButton>
      </div>

      <div className={classes.buttonContainer}>
        {!hideNextButton && (
          <IconButton onClick={onNextMonthClick}>
            <ChevronIcon direction={Direction.Right} />
          </IconButton>
        )}
      </div>
    </Box>
  );
}
