import cx from "classnames";
import { forwardRef } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { BoxProps } from "@mui/material";
import { makeStyles, useIsTabletDown, useTheme } from "../../../theme/Theme";
import { Box } from "../Box";
import { AngleLeftIcon } from "../../icons/AngleLeftIcon";

const useStyles = makeStyles(() => ({
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
}));

interface SelectHeaderProps extends BoxProps {
  readonly onClick: (event: any) => void;
  readonly open: boolean;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly titleProps?: TypographyProps;
}

export const SelectHeader: React.FC<SelectHeaderProps> = forwardRef(
  (
    { onClick, placeholder, disabled = false, children, open, titleProps, className, ...props },
    ref,
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallDown = useIsTabletDown();

    const HeaderContent = children || (
      <Typography {...titleProps} variant="input1" color={theme.palette.gary.main}>
        {placeholder || "Выберите вариант"}
      </Typography>
    );

    return (
      <Box
        px={theme.typography.pxToRem(16)}
        {...props}
        onClick={onClick}
        className={cx(classes.spaceBetween, className)}
        clickable={true}
        disabled={disabled}
        ref={ref}
      >
        {HeaderContent}
        <AngleLeftIcon
          color={theme.palette.grape.main}
          style={{
            transform: open ? "rotate(90deg)" : "rotate(-90deg)",
            transition: isSmallDown ? undefined : "transform 150ms ease-in-out",
            flexShrink: 0,
            marginLeft: theme.typography.pxToRem(12),
          }}
        />
      </Box>
    );
  },
);
