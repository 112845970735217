import React, { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { IMaskInput } from "react-imask";

import { makeStyles } from "../../theme/Theme";
import { limitByLength, validateField } from "../../utils/FieldUtils";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { FormControl } from "../form/FormControl";

interface StylesProps {
  readonly disabled?: boolean;
  readonly inputTextColor?: string;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: (props: StylesProps) => ({
    "&.MuiTextField-root": {
      "& > .MuiInputBase-root": {
        opacity: props.disabled ? 0.5 : 1,
        overflow: "hidden",
        backgroundColor: theme.palette.back.main,
        borderRadius: theme.typography.pxToRem(20),
        "&.MuiInputBase-multiline": {
          padding: 0,
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          transition: "border-color 270ms ease-in-out",
          borderRadius: theme.typography.pxToRem(20),
          borderColor: theme.palette.common.transparent,
        },
        "& > .MuiInputBase-input": {
          height: "20px",
          color: (props) => props.inputTextColor || theme.palette.cow.main,
          padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(23)}`,
          fontSize: 16,
          lineHeight: "19px",
          fontWeight: 400,

          borderColor: theme.palette.common.transparent,

          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(17),
          },

          "&:focus + .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),
          },
        },
        "&:hover:not(.Mui-disabled)": {
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),

            [theme.breakpoints.down(Breakpoints.Tablet)]: {
              borderColor: theme.palette.common.transparent,
            },
          },
        },
      },
    },
  }),
}));

export interface TextInputProps extends Omit<TextFieldProps, "value"> {
  readonly value?: string;
  maxlength?: string | number;
  inputCharactersType?: Survey.InputCharactersType;
  readonly inputTextColor?: string;
  mask?: string; // New prop for the mask
}

export function TextInput({
  className,
  inputTextColor,
  disabled,
  maxlength,
  inputCharactersType,
  mask, // New prop for the mask
  ...props
}: TextInputProps) {
  const classes = useStyles({ disabled, inputTextColor });
  const refInput = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (!refInput.current) return;
    const input = refInput.current;

    if (maxlength) limitByLength(input, maxlength.toString());
  }, []);

  const inputComponent = (
    <TextField
      inputRef={refInput}
      autoCorrect="off"
      variant="outlined"
      autoComplete="off"
      autoCapitalize="off"
      onKeyUp={(e) => (inputCharactersType ? validateField(e, inputCharactersType) : undefined)}
      disabled={disabled}
      inputMode={inputCharactersType === "only-numbers" ? "numeric" : props.inputProps?.inputMode}
      className={cx(classes.root, className)}
      {...props}
      InputProps={{
        inputComponent: mask ? InputWithMask : undefined,
        inputProps: { maskCustom: mask },
      }}
    />
  );

  return React.cloneElement(inputComponent, {
    className: cx(classes.root, className),
  });
}

const InputWithMask = React.forwardRef<HTMLInputElement | null, any>((props, ref) => {
  const { inputTextColor, mask, disabled, ...otherProps } = props;
  const classes = useStyles({ disabled, inputTextColor });

  return (
    <FormControl className={classes.root}>
      <IMaskInput
        mask={otherProps.maskCustom}
        style={{
          paddingLeft: "23px",
          paddingBottom: "16px",
          paddingTop: "16px",
        }}
        inputRef={ref}
        autoCorrect="off"
        autoComplete="off"
        autoCapitalize="off"
        placeholder={props.placeholder}
        disabled={disabled}
        {...otherProps}
      />
    </FormControl>
  );
});
