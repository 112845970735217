import React from "react";

import { useColor } from "../../hooks/useColor";

export function AngleLeftIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.total.main });

  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={iconColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6405 5.34685C16.1198 5.80931 16.1198 6.55911 15.6405 7.02157L9.9629 12.5L15.6405 17.9784C16.1198 18.4409 16.1198 19.1907 15.6405 19.6532C15.1613 20.1156 14.3842 20.1156 13.9049 19.6532L7.35946 13.3374C6.88018 12.8749 6.88018 12.1251 7.35946 11.6626L13.9049 5.34685C14.3842 4.88438 15.1613 4.88438 15.6405 5.34685Z"
      />
    </svg>
  );
}
