import React from "react";

import { useColor } from "../../hooks/useColor";

export function ErrorIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.grape.main });

  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3688 3.44969C9.6819 3.6136 8.12475 4.23509 6.75883 5.28685C6.09295 5.79907 5.22901 6.76204 4.73045 7.54744C4.51532 7.8855 4.0953 8.74603 3.94505 9.15581C3.77772 9.61339 3.59674 10.3168 3.50795 10.8598C3.40209 11.5257 3.40209 12.7891 3.50795 13.455C3.66162 14.3975 3.90407 15.1556 4.31726 15.9991C4.76801 16.9176 5.23584 17.5699 5.98709 18.3245C6.74176 19.0758 7.39399 19.5436 8.31256 19.9944C9.15602 20.4075 9.9141 20.65 10.8566 20.8037C11.5225 20.9095 12.7859 20.9095 13.4518 20.8037C14.3943 20.65 15.1524 20.4075 15.9958 19.9944C16.9144 19.5436 17.5667 19.0758 18.3213 18.3245C19.0726 17.5699 19.5404 16.9176 19.9912 15.9991C20.2917 15.3878 20.4419 14.9883 20.6058 14.3736C20.8209 13.5541 20.8722 13.1067 20.8722 12.1574C20.8722 11.2081 20.8209 10.7608 20.6058 9.94121C20.4419 9.32655 20.2917 8.92702 19.9912 8.31577C19.5404 7.39719 19.0726 6.74496 18.3213 5.9903C17.5667 5.23904 16.9144 4.77121 15.9958 4.32046C15.1558 3.90727 14.3772 3.66141 13.4689 3.51799C12.9669 3.43945 11.8469 3.40188 11.3688 3.44969ZM13.1035 5.07172C15.5178 5.40978 17.5735 6.9157 18.615 9.11141C19.0828 10.0949 19.2911 11.0374 19.2911 12.1574C19.2911 12.8096 19.2365 13.2809 19.0896 13.8853C18.4682 16.4157 16.4125 18.4714 13.8821 19.0929C13.2777 19.2397 12.8064 19.2943 12.1542 19.2943C11.502 19.2943 11.0307 19.2397 10.4263 19.0929C7.89596 18.4714 5.84026 16.4157 5.21876 13.8853C5.07193 13.2809 5.01729 12.8096 5.01729 12.1574C5.01729 11.5052 5.07193 11.0339 5.21876 10.4295C5.84026 7.89575 7.89596 5.84004 10.4332 5.22197C10.6619 5.16733 10.9932 5.09903 11.1673 5.07513C11.6147 5.01025 12.6425 5.00683 13.1035 5.07172Z"
        fill={iconColor}
      />
      <path
        d="M8.53452 9.43917C8.30915 9.48697 8.22719 9.53136 8.04962 9.69186C7.64326 10.0572 7.6057 10.73 7.96767 11.1534C8.03255 11.2285 8.16573 11.3344 8.26475 11.389C8.43208 11.4812 8.47989 11.4915 8.75648 11.4915C9.10479 11.4915 9.2687 11.43 9.50091 11.208C10.0234 10.7061 9.86629 9.80455 9.20382 9.51088C8.97845 9.40843 8.76673 9.38794 8.53452 9.43917Z"
        fill={iconColor}
      />
      <path
        d="M15.3403 9.42551C14.5207 9.58942 14.2066 10.6343 14.8076 11.208C15.0398 11.43 15.2037 11.4915 15.552 11.4915C15.8286 11.4915 15.8764 11.4812 16.0437 11.389C16.2725 11.2627 16.4364 11.0749 16.5286 10.829C16.7096 10.3441 16.4842 9.75333 16.0232 9.51429C15.9379 9.47331 15.5588 9.38453 15.5042 9.39477C15.4939 9.39819 15.4188 9.41185 15.3403 9.42551Z"
        fill={iconColor}
      />
      <path
        d="M11.5052 12.9942C10.7198 13.1034 9.99928 13.4074 9.35389 13.9025C8.83142 14.2986 8.67093 14.5274 8.67093 14.8621C8.67434 15.3026 9.01924 15.6406 9.46316 15.6406C9.69878 15.6372 9.86269 15.5587 10.1564 15.2991C10.73 14.7938 11.3823 14.5479 12.154 14.5479C12.9258 14.5479 13.578 14.7938 14.1517 15.2991C14.4488 15.5621 14.6093 15.6406 14.8517 15.6406C15.2922 15.6406 15.6371 15.2957 15.6371 14.8552C15.6371 14.5274 15.4732 14.2952 14.9541 13.9025C13.9604 13.141 12.7448 12.8234 11.5052 12.9942Z"
        fill={iconColor}
      />
    </svg>
  );
}
