import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../theme/Theme";

interface LabelProps {
  readonly title: string;
  readonly amount: string;
  readonly proportion: string;
}

export function ManageFundsStrategyLabel({ title, proportion, amount }: LabelProps) {
  const theme = useTheme();

  return (
    <Stack width="calc(100% - 54px)" spacing={theme.typography.pxToRem(9)} direction="column">
      <Stack spacing={1} direction="row" alignItems="end">
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          variant="button3"
          color={theme.palette.cow.main}
        >
          {title}
        </Typography>
        <Typography variant="button1" sx={{ opacity: 0.5 }} color={theme.palette.cow.main}>
          {proportion}
        </Typography>
      </Stack>
      <Typography variant="input2" color={theme.palette.cow.main}>
        {amount}
      </Typography>
    </Stack>
  );
}
