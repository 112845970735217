import cx from "classnames";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { Box, ButtonBase, ButtonBaseProps } from "@mui/material";

import { makeStyles, useTheme } from "../../theme/Theme";
import { dateToFormat, SHORT_STRING_DATE_FORMAT } from "../../utils";

type StylesProps = Pick<Props, "active">;

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: (props) => (props.active ? theme.palette.bloom.main : theme.palette.total.main),
  },
  button: {
    flex: 1,
    "&.MuiButtonBase-root": {
      padding: `${theme.typography.pxToRem(6)} 0`,
      justifyContent: "flex-start",
      borderBottom: `1px solid ${theme.palette.gary.main}`,
    },
  },
}));

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  readonly active: boolean;
  readonly title: string;
  readonly date: DateTime | undefined;
  readonly buttonProps?: Omit<ButtonBaseProps, "children">;
}

export function DatePickerRangeButton({
  date,
  title,
  active,
  className,
  buttonProps,
  ...props
}: Props) {
  const classes = useStyles({ active });
  const theme = useTheme();

  return (
    <div {...props} className={cx(classes.root, className)}>
      <Typography variant="input2" className={classes.title}>
        {title}
      </Typography>
      <ButtonBase {...buttonProps} className={cx(classes.button, buttonProps?.className)}>
        {date ? (
          <Typography variant="input1">
            {dateToFormat({ date, format: SHORT_STRING_DATE_FORMAT })}
          </Typography>
        ) : (
          <Box height={theme.typography.pxToRem(17)} />
        )}
      </ButtonBase>
    </div>
  );
}
