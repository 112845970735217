import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "../../theme/Theme";
import { AppRoutes } from "../../constants/AppRoutes";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { AuthPromo } from "../../components/auth/AuthPromo";
import { AuthSidebar } from "../../components/auth/AuthSidebar";
import { formatAuthenticateBody } from "../../helpers/AuthHelpers";
import {
  useCheckCodeMutation,
  useLazyRegistrationInfoUserQuery,
  useSendCodeMutation,
} from "../../api/authApi";
import { changeSessionId } from "../../slices/authSlice";
import { Auth } from "../../types/app/auth";
import { useRegistration } from "../../hooks/useRegistration";
import { useLazyGetErrorByStrategyQuery } from "../../api/strategyApi";
import { createUrl } from "../../utils";
import { useAuth } from "../../components/auth/AuthContext";
import { changeModeUser } from "../../slices/applicationSlice";
import { openSnackbarError } from "../../utils/NotificationsUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.flexFull,
    ...theme.mixins.flexColumn,
  },
  content: {
    ...theme.mixins.flexFull,
    [theme.breakpoints.down(Breakpoints.Tablet)]: { flexDirection: "column-reverse" },
  },
}));

export function AuthPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { beginInputPersonalData, buyStrategy, setStrategyBuyId } = useRegistration();
  const { enterInSystem } = useAuth();
  const [getErrorByStrategy] = useLazyGetErrorByStrategyQuery();
  const [registrationInfoUser] = useLazyRegistrationInfoUserQuery();

  const [sendCodeMutation] = useSendCodeMutation();
  const [checkCodeMutation] = useCheckCodeMutation();

  const [authPhone, setAuthPhone] = useState<string | undefined>();
  const [searchParams] = useSearchParams();
  const candidateBuyStrategyId = searchParams.get("strategyId");
  const toHotUserSurvey = (strategyID: string | undefined) => {
    navigate(
      createUrl(AppRoutes.Survey, {
        query: {
          scenario: "PurchasingStrategyDemo",
          strategyId: strategyID,
          isHot: 1,
        },
      }),
    );
  };
  const enterCurrentUser = async (values: Auth.AuthenticateBodyProps, isDemo: Store.modeUser) => {
    setAuthPhone(values.phone);
    enterInSystem({
      code: values.code,
      phone: values.phone,
      modeUser: isDemo,
    });
    // eslint-disable-next-line promise/no-nesting
    return await getErrorByStrategy()
      .then((data) => {
        if (data?.data?.data?.idStrategy) {
          navigate(AppRoutes.ProfileLink);
          return;
        }
        navigate(AppRoutes.DashboardBriefcaseLink);
      })
      .catch(() => {
        navigate(AppRoutes.DashboardBriefcaseLink);
      });
  };
  const submitHandler = (values: Auth.AuthenticateBodyProps) => {
    if (values.code) {
      return checkCodeMutation(formatAuthenticateBody(values))
        .unwrap()
        .then(async (data) => {
          if (data?.data?.statusUser === "cold") {
            buyStrategy();
            dispatch(changeModeUser("cold"));
            await registrationInfoUser()
              .unwrap()
              .then((dataInfoUser) => {
                if (dataInfoUser?.data?.checkPointSaveInfo === "survey") {
                  setAuthPhone(values.phone);
                  enterInSystem({
                    code: values.code,
                    phone: values.phone,
                    modeUser: "hot",
                  });
                  toHotUserSurvey(dataInfoUser.data.idTargetStrategy);
                } else if (dataInfoUser?.data?.checkPointSaveInfo === "personal data") {
                  enterInSystem({
                    code: values.code,
                    phone: values.phone,
                    modeUser: "cold",
                  });
                  setAuthPhone(values.phone);
                  beginInputPersonalData(values.phone);
                } else {
                  enterCurrentUser(values, "cold");
                }
              })
              .catch(openSnackbarError);
            return;
          }
          return enterCurrentUser(values, "simple");
        });
    }

    return sendCodeMutation(formatAuthenticateBody(values))
      .unwrap()
      .then((x) => {
        if (x?.sessionId) {
          dispatch(changeSessionId(x.sessionId));
          setAuthPhone(values.phone);
        }
      });
  };
  const toAgreementClick = () => {
    window.open(`${process.env.REACT_APP_API_HOST}/api/public/agreement/download`);

    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Пользовательское соглашение" },
    // });
  };
  const toPrivacyPolicy = () => {
    window.open("https://www.fbaugust.ru/upload/iblock/33b/4qe11520fodb5aix95yhqs6pibpv4myr.txt");

    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Политика конфиденциальности" },
    // });
  };
  const toConsentProcessingPersonalData = async () => {
    window.open("https://be.fbaugust.ru:2841/api/agreement_web");
    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Согласие на обработку персональных данных" },
    // });
  };

  useEffect(() => {
    setStrategyBuyId(candidateBuyStrategyId);
  }, [candidateBuyStrategyId]);
  useEffect(() => {
    dispatch(changeModeUser("simple"));
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AuthPromo />

        <AuthSidebar
          phone={authPhone}
          setPhone={setAuthPhone}
          onSubmit={submitHandler}
          onBackClick={() => setAuthPhone(undefined)}
          onAgreementClick={toAgreementClick}
          onConsentProcessingPersonalData={toConsentProcessingPersonalData}
          onPrivacyPolicy={toPrivacyPolicy}
        />
      </div>
    </div>
  );
}
