import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { RootState } from "../store/configureStore";
import { selectCurrenciesResult } from "./commonApi";
import { ApiMethod, fetchQuery } from "../helpers/ApiHelpers";
import { DEFAULT_CURRENCY } from "../constants/CurrencyConstants";

enum SettingsApiTypes {
  Settings = "settings",
}

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: fetchQuery(),
  tagTypes: [SettingsApiTypes.Settings],
  endpoints: (builder) => ({
    settings: builder.query<Application.ResponseProps<Profile.SettingsProps>, void>({
      query: (query) => createUrl(ApiRoutes.ProfileSettings, { query }),
      providesTags: [SettingsApiTypes.Settings],
    }),
    changeSettings: builder.mutation<void, Profile.SettingsBodyProps>({
      query: (body) => ({
        body,
        method: ApiMethod.Patch,
        url: ApiRoutes.ProfileSettings,
      }),
      onQueryStarted: async (data, { queryFulfilled, dispatch }) => {
        const patchResult = dispatch(
          settingsApi.util.updateQueryData("settings", undefined, (draft) => {
            draft.data = {
              ...draft.data,
              ...data,
            } as Profile.SettingsProps;
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    twoFactorAuthInfo: builder.query<
      Application.ResponseProps<Profile.TwoFactorAuthInfoProps>,
      void
    >({
      query: () => ApiRoutes.TwoFactorAuthInfo,
    }),
    twoFactorAuthSendSms: builder.mutation<void, Profile.TwoFactorAuthSendCodeBodyProps>({
      query: (query) => createUrl(ApiRoutes.TwoFactorAuthSendSmsCode, { query }),
    }),
    twoFactorAuthDisable: builder.mutation<void, Profile.TwoFactorAuthDisableBodyProps>({
      query: (query) => createUrl(ApiRoutes.TwoFactorAuthDisable, { query }),
      invalidatesTags: [SettingsApiTypes.Settings],
    }),
    twoFactorAuthGoogleGenerate: builder.mutation<
      Application.ResponseProps<Profile.TwoFactorAuthGoogleGenerateProps>,
      Profile.TwoFactorAuthGoogleGenerateBodyProps
    >({ query: (query) => createUrl(ApiRoutes.TwoFactorAuthGoogleCode, { query }) }),
    twoFactorAuthEnable: builder.mutation<void, Profile.TwoFactorAuthEnableBodyProps>({
      query: (query) => createUrl(ApiRoutes.TwoFactorAuthEnable, { query }),
      invalidatesTags: [SettingsApiTypes.Settings],
    }),
  }),
});

export const {
  useSettingsQuery,
  useLazySettingsQuery,
  useChangeSettingsMutation,
  useTwoFactorAuthInfoQuery,
  useTwoFactorAuthEnableMutation,
  useTwoFactorAuthDisableMutation,
  useTwoFactorAuthSendSmsMutation,
  useTwoFactorAuthGoogleGenerateMutation,
} = settingsApi;

/**
 * Selectors
 */

export const selectSettingsResult = settingsApi.endpoints.settings.select();

export function settingsCurrencySelector(state: RootState): Currency.Props {
  return createSelector(
    [selectSettingsResult, selectCurrenciesResult],
    (profileSettings, currencies) => {
      const list = currencies?.data?.data || [];

      const currency = list.find((x) => x.code === profileSettings?.data?.data?.currency);

      return currency || DEFAULT_CURRENCY;
    },
  )(state);
}

export function settingsSelector(state: RootState): Profile.SettingsProps | undefined {
  return createSelector(
    selectSettingsResult,
    (profileSettings) => profileSettings?.data?.data,
  )(state);
}
