import cx from "classnames";
import React, { ReactNode } from "react";

import { LogoIcon } from "../icons/LogoIcon";
import { makeStyles, useIsTabletDown } from "../../theme/Theme";
import { AppLayoutContent } from "./AppLayoutContent";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { modeUserInSystemSelector } from "../../slices/applicationSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "inherit",

    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      minHeight: theme.typography.pxToRem(60),
      maxHeight: theme.typography.pxToRem(60),
      paddingLeft: theme.typography.pxToRem(20),
      paddingRight: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      minHeight: theme.typography.pxToRem(90),
      maxHeight: theme.typography.pxToRem(90),
      paddingLeft: theme.typography.pxToRem(150),
      paddingRight: theme.typography.pxToRem(150),
    },

    [theme.breakpoints.up(Breakpoints.Desktop)]: {
      minHeight: theme.typography.pxToRem(100),
      maxHeight: theme.typography.pxToRem(100),
      paddingLeft: theme.typography.pxToRem(210),
      paddingRight: theme.typography.pxToRem(210),
    },

    marginLeft: 0,
    marginRight: 0,
  },
}));

interface Props {
  readonly className?: string;
  readonly children?: ReactNode;
}

export function AppLayoutHeaderContent({ children, className }: Props) {
  const classes = useStyles();
  const modeUser = useSelector(modeUserInSystemSelector);

  const isHotUser = modeUser === "hot";
  const isSmallDown = useIsTabletDown();

  return (
    <AppLayoutContent className={cx(classes.root, className)}>
      {!isHotUser ? (
        <NavLink to={AppRoutes.DashboardBriefcaseLink}>
          <LogoIcon />
        </NavLink>
      ) : (
        !isSmallDown && <LogoIcon />
      )}

      {children}
    </AppLayoutContent>
  );
}
