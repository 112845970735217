import cx from "classnames";
import { isString } from "lodash";
import React, { ForwardRefExoticComponent, isValidElement } from "react";
import Typography from "@mui/material/Typography";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";

import { Loader } from "./Loader";
import { formatHexToRGBA } from "../../utils";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { makeStyles, useTheme } from "../../theme/Theme";
import { AppRoutes } from "../../constants/AppRoutes";
import { SizeType } from "../../types/app/application.enum";

interface StylesProps {
  readonly isPrimaryType: boolean;
  readonly isSecondaryType: boolean;
  readonly isSecondaryFilledType: boolean;
  readonly isGrapeType: boolean;
  readonly backgroundColor: string;
  readonly disabled?: boolean;
  isSmallSize: boolean;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: {
    "&.MuiButton-root.Mui-disabled": {
      boxShadow: "none",
    },
    "&.MuiButton-root": {
      textTransform: "none",
      height: ({ isSmallSize }) => (isSmallSize ? 36 : 51),
      borderRadius: (props) => {
        if (props.isSmallSize) {
          return theme.typography.pxToRem(12);
        }
        if (props.isSecondaryFilledType) {
          return theme.typography.pxToRem(12);
        }
        return theme.typography.pxToRem(20);
      },
      boxShadow: (props) => (props.isPrimaryType ? theme.customShadows.button : "none"),
      color: (props) =>
        props.isPrimaryType || props.isGrapeType
          ? theme.palette.const.main
          : theme.palette.grape.main,
      backgroundColor: (props) => props.backgroundColor,
      border: (props) =>
        props.isPrimaryType || props.isGrapeType || props.isSecondaryFilledType
          ? `1px solid transparent`
          : `1px solid ${theme.palette.grape.main}`,

      [theme.breakpoints.up(Breakpoints.Tablet)]: {
        "&:hover": {
          textAlign: "center",
          color: (props) =>
            props.isSecondaryType ? theme.palette.grape.main : theme.palette.const.main,
          backgroundColor: (props) => props.backgroundColor,
          boxShadow: (props) => {
            if (props.isPrimaryType || props.isSecondaryFilledType) {
              return theme.customShadows.hover;
            }
            return "none";
          },
        },
        "&:active": {
          color: theme.palette.const.main,
          boxShadow: (props) =>
            props.isPrimaryType
              ? theme.customShadows.tap
              : props.isGrapeType
              ? theme.customShadows.grapeTap
              : "none",
        },
      },

      [theme.breakpoints.down(Breakpoints.Tablet)]: {
        "&:hover": {
          boxShadow: "none",
          color: theme.palette.const.main,
          backgroundColor: (props) => props.backgroundColor,
        },

        "&:active": {
          boxShadow: "none",
          color: theme.palette.const.main,
          backgroundColor: (props) => props.backgroundColor,
          border: (props) =>
            props.isPrimaryType || props.isGrapeType
              ? `1px solid transparent`
              : `1px solid ${theme.palette.bloom.main}`,
        },
      },

      "&.Mui-disabled": {
        color: theme.palette.const.main,
        boxShadow: (props) => {
          if (props.disabled) {
            return "none";
          }

          if (props.isPrimaryType) {
            return theme.customShadows.button;
          }

          return "none";
        },
        backgroundColor: (props) =>
          props.disabled
            ? formatHexToRGBA({ color: props.backgroundColor, alpha: 0.55 })
            : props.backgroundColor,
      },
    },
  },
}));

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  SecondaryFilled = "secondaryFilled",
  Grape = "grape",
}

export interface ActionButtonProps extends Omit<MuiButtonProps, "size"> {
  readonly loading?: boolean;
  readonly buttonType?: ButtonType;
  to?: AppRoutes;
  component?: ForwardRefExoticComponent<any>;
  sizeButton?: SizeType;
}

export function ActionButton({
  loading,
  children,
  disabled,
  className,
  buttonType = ButtonType.Primary,
  sizeButton = SizeType.BIG,
  ...props
}: ActionButtonProps) {
  const isPrimaryType = buttonType === ButtonType.Primary;
  const isSecondaryType = buttonType === ButtonType.Secondary;
  const isGrapeType = buttonType === ButtonType.Grape;
  const isSecondaryFilledType = buttonType === ButtonType.SecondaryFilled;

  const isSmallSize = sizeButton === SizeType.SMALL;

  const theme = useTheme();
  const backgroundColor =
    isPrimaryType || isSecondaryFilledType
      ? theme.palette.bloom.main
      : isGrapeType
      ? theme.palette.grape.main
      : theme.palette.common.transparent;

  const classes = useStyles({
    backgroundColor,
    disabled,
    isPrimaryType,
    isSecondaryType,
    isGrapeType,
    isSecondaryFilledType,
    isSmallSize,
  });

  const loaderSize = isSmallSize ? 14 : 17;
  const buttonTypographyVariant = isSmallSize ? "text3" : "button";

  return (
    <MuiButton
      type="button"
      variant="contained"
      disabled={disabled || loading}
      className={cx(classes.root, className)}
      {...props}
    >
      {loading && <Loader size={loaderSize} color={theme.palette.const.main} />}

      {!loading && (
        <>
          {isValidElement(children) && children}

          {isString(children) && (
            <Typography variant={buttonTypographyVariant}>{children}</Typography>
          )}
        </>
      )}
    </MuiButton>
  );
}
