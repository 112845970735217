import cx from "classnames";
import { ReactElement } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { makeStyles, useTheme } from "../../../theme/Theme";
import { Box } from "../Box";
import { BoxProps } from "@mui/material";

const useSelectOptionStyles = makeStyles((theme) => ({
  selectOption: {
    transition: "color 150ms ease-in-out",
    color: theme.palette.total.main,
    "&:hover": {
      color: theme.palette.grape.main,
    },
    "&:focus-within": {
      color: theme.palette.grape.main,
    },
  },
}));

export type OptionValue = number | string;

export interface OptionProps extends BoxProps {
  readonly title: string;
  readonly value: OptionValue;
  readonly icon?: ReactElement;
  readonly onClick?: () => void;
  classNamesProps?: string;
  readonly typographyProps?: TypographyProps;
}

export function SelectOption({
  title,
  onClick,
  classNamesProps,
  icon,
  value,
  typographyProps,
  ...props
}: OptionProps) {
  const theme = useTheme();
  const selectOptionClasses = useSelectOptionStyles();

  return (
    <Box
      className={cx(selectOptionClasses.selectOption, classNamesProps)}
      clickable={true}
      disabled={false}
      {...props}
    >
      <Stack
        onClick={onClick}
        direction="row"
        alignItems="center"
        spacing={theme.typography.pxToRem(16)}
      >
        {icon}
        <Typography color="currentColor" variant="text2" {...typographyProps}>
          {title}
        </Typography>
      </Stack>
    </Box>
  );
}
