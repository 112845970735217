import React from "react";

import { useColor } from "../../hooks/useColor";

export function PopoverArrowIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.walter.main });

  return (
    <svg
      {...props}
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.59035 13.4691C0.733156 12.6773 0.733157 11.3227 1.59035 10.5309L11.6429 1.24475C12.9239 0.0614482 15 0.969993 15 2.71386L15 21.2861C15 23.03 12.9239 23.9385 11.6429 22.7552L1.59035 13.4691Z"
        fill={iconColor}
      />
    </svg>
  );
}
