import cx from "classnames";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";

export const DESKTOP_MAX_WIDTH_INPUT = 588;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    maxWidth: theme.typography.pxToRem(DESKTOP_MAX_WIDTH_INPUT),
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginTop: 14,
      maxWidth: "100%",
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginTop: 30,
      maxWidth: "100%",
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      maxWidth: DESKTOP_MAX_WIDTH_INPUT,
    },
  },
}));

interface Props extends BoxProps {}

export function ManageFundsRoot({ className, ...props }: Props) {
  const classes = useStyles();

  return <Box className={cx(classes.root, className)} {...props} />;
}
