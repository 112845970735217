import { store } from "../store/configureStore";
import {
  closeSnackbarActions,
  openSnackbarActions,
  openSnackbarErrorActions,
} from "../slices/notificationSlice";
import { SnackbarProps } from "../components/ui/Snackbar";

export const closeSnackbar = () => {
  store.dispatch(closeSnackbarActions());
};
export const openSnackbar = (data: SnackbarProps) => {
  store.dispatch(openSnackbarActions(data));
};
export const openSnackbarError = (e: any) => {
  store.dispatch(openSnackbarErrorActions(e));
};
