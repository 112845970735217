import React from "react";
import cx from "classnames";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../theme/Theme";

export enum ReportingPeriodItemType {
  Withdrawn = "withdrawn",
  Transferred = "transferred",
}

interface StylesProps {
  readonly type: ReportingPeriodItemType;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  title: { color: theme.palette.gary.main },
  root: { display: "flex", alignItems: "center", flexWrap: "nowrap" },
  marker: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
    backgroundColor: (props) => {
      if (props.type === ReportingPeriodItemType.Transferred) {
        return theme.palette.icarus.main;
      }

      return theme.palette.sun.main;
    },
  },
}));

interface Props {
  readonly value: string;
  readonly className?: string;
  readonly type: ReportingPeriodItemType;
  readonly title: string;
}

export function ReportingPeriodItem({ type, value, className, title }: Props) {
  const classes = useStyles({ type });

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.marker} />

      <Typography variant="text2" className={classes.title}>
        {title} {value}
      </Typography>
    </div>
  );
}
