import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { DatePickerCellSelect } from "./DatePickerCellSelect";
import { toString } from "lodash";
interface Props {
  dataForSelect: Array<Application.OptionSelect>;
  selectValue: string | number | undefined;
  onChange: (value: string | number) => void;
  mb?: number;
}

export function DatePickerSelect({ dataForSelect, selectValue, mb = 0, onChange }: Props) {
  const refList = useRef();

  useEffect(() => {
    if (refList.current) {
      const nodeElemForSelect = Array.from(
        // @ts-ignore
        refList.current?.children[0].children,
      ) as HTMLDivElement[];
      const selectedElement = nodeElemForSelect.find(
        (el) => toString(el.textContent) === toString(selectValue),
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selectValue]);

  return (
    <Box ref={refList} sx={{ flex: 1, justifyContent: "center" }}>
      <Grid container={true} columns={3}>
        {dataForSelect.map((select, index) => (
          <DatePickerCellSelect
            onClick={onChange}
            mb={mb}
            value={select}
            key={index}
            isSelect={toString(select.value) === toString(selectValue)}
          />
        ))}
      </Grid>
    </Box>
  );
}
