import React from "react";

import { useIsLaptopDown } from "../../theme/Theme";
import { PlaceholderBox, PlaceholderBoxProps } from "../placeholder/PlaceholderBox";

interface Props extends PlaceholderBoxProps {}

export function ManageFundsInputPlaceholder({ ...props }: Props) {
  const isMediumDown = useIsLaptopDown();

  return (
    <PlaceholderBox {...props} width={isMediumDown ? undefined : 588} height={56} radius={16} />
  );
}
