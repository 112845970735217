import { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";

import { CloseIcon } from "../icons/CloseIcon";
import { makeStyles, useTheme } from "../../theme/Theme";
import { DatePicker, DatePickerProps, DatePickerType } from "./DatePicker";

interface StylesProps {
  contentSize?: number | null;
}

const useStyles = makeStyles<StylesProps>((theme) => {
  return {
    title: { color: theme.palette.total.main },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.typography.pxToRem(0),
      paddingTop: theme.typography.pxToRem(20),
      paddingLeft: theme.typography.pxToRem(20),
      paddingRight: theme.typography.pxToRem(20),
    },
  };
});

export interface DatePickerContainerProps extends DatePickerProps {
  readonly onCloseClick: () => void;
}

export function DatePickerContainer({ onCloseClick, ...props }: DatePickerContainerProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles({ contentSize: contentRef.current?.clientHeight });
  const theme = useTheme();

  const title = props.type === DatePickerType.Range ? "Выберите период" : "Выберите дату";

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.title} sx={{ fontSize: 24, fontWeight: 590 }}>
          {title}
        </Typography>

        <IconButton onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      </div>

      <Box overflow="auto" className="hide-scrollbar">
        <DatePicker
          px={theme.typography.pxToRem(20)}
          pb={theme.typography.pxToRem(60)}
          overflow="auto"
          ref={contentRef}
          {...props}
        />
      </Box>
    </>
  );
}
