import { isDate, isString } from "lodash";
import { DateTime } from "luxon";
import { DateTimeJSOptions } from "luxon/src/datetime";

export const SHORT_TIME_FORMAT = "HH:mm";
export const HUMAN_DATE_FORMAT = "d MMMM yyyy";
export const SHORT_DATE_FORMAT = "dd.MM.yyyy";
export const TIME_STAMP_FORMAT = "yyyyMMddHHmmss";
export const SHORT_URL_DATE_FORMAT = "yyyy-MM-dd";
export const FULL_DATE_JOURNAL_OPERATIONS_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const SHORT_DATE_JOURNAL_OPERATIONS_FORMAT = "dd.MM.yyyy HH:mm";
export const SHORT_STRING_DATE_FORMAT = "dd MMM yyyy";
export const STRING_DMY_FORMAT = "d MMMM yyyy г.";
export const SHORT_DMY_DATE_FORMAT = "d MMMM";
export const FULL_DATE_FORMAT = "dd.MM.yyyy HH-mm-ss";
export const DELIVERY_FULL_FORMAT = "В EEE., d MMMM к HH:mm";
export const STRING_DATE_WITH_TIME_FORMAT = "d MMMM в HH:mm";

export function getTodayFormat(format) {
  return getTodayDateTime().toFormat(format);
}

export function isToday(date?: Date | string) {
  if (!date) {
    return false;
  }

  const dateTime = isDate(date) ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

  const dateMillis = dateTime.startOf("day").toMillis();
  const nowMillis = getTodayDateTime().startOf("day").toMillis();

  return nowMillis === dateMillis;
}

export function isTomorrow(date?: Date | string) {
  if (!date) {
    return false;
  }

  const dateTime = isDate(date) ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

  const dateMillis = dateTime.minus({ days: 1 }).startOf("day").toMillis();
  const nowMillis = getTodayDateTime().startOf("day").toMillis();

  return nowMillis === dateMillis;
}

export function isYesterday(date?: Date | string) {
  if (!date) {
    return false;
  }

  const dateTime = isDate(date) ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

  const dateMillis = dateTime.plus({ days: 1 }).startOf("day").toMillis();
  const nowMillis = getTodayDateTime().startOf("day").toMillis();

  return nowMillis === dateMillis;
}

interface DateToFormatProps {
  readonly format: string;
  readonly date?: Date | string | DateTime;
}

export function dateToFormat({ date, format }: DateToFormatProps): string {
  if (!date) {
    return "";
  }

  const normalizedDate = normalizeDateTime(date);

  return normalizedDate?.toFormat(format);
}

interface DateFromFormatProps {
  readonly toFormat: string;
  readonly fromFormat: string;
  readonly date: string | null | undefined;
}

export function dateFromFormat({ fromFormat, date, toFormat }: DateFromFormatProps): string {
  if (!date) {
    return "";
  }

  return DateTime.fromFormat(date, fromFormat).setLocale("ru").toFormat(toFormat);
}

interface DateTimeFromFormatProps {
  readonly date: string;
  readonly format: string;
}

export function dateTimeFromFormat({ format, date }: DateTimeFromFormatProps): DateTime {
  return DateTime.fromFormat(date, format, { locale: "ru" });
}

export function getTodayDateTime(): DateTime {
  return DateTime.now().setLocale("ru");
}

export function getMinimumDateTime(): DateTime {
  return DateTime.local(1910, 1, 1).setLocale("ru");
}

export function getTomorrowDateTime(): DateTime {
  return getTodayDateTime().plus({ days: 1 });
}

export function getYesterdayDateTime(): DateTime {
  return getTodayDateTime().minus({ days: 1 });
}

interface DateToDateTimeProps {
  readonly date: Date | string;
  readonly fromFormat?: string;
}

export function dateToDateTime({ date, fromFormat }: DateToDateTimeProps): DateTime {
  if (isDate(date)) {
    return DateTime.fromJSDate(date).setLocale("ru");
  }

  if (fromFormat) {
    return DateTime.fromFormat(date, fromFormat).setLocale("ru");
  }

  return DateTime.fromISO(date).setLocale("ru");
}

export function getLocalTimeStamp(opts?: DateTimeJSOptions): string {
  return DateTime.local(opts).toFormat(TIME_STAMP_FORMAT);
}

export function normalizeDateTime(date: string | Date | DateTime): DateTime {
  if (isDate(date)) {
    return DateTime.fromJSDate(date).setLocale("ru");
  }

  if (isString(date)) {
    return DateTime.fromISO(date).setLocale("ru");
  }

  if (date instanceof DateTime) {
    return date.setLocale("ru");
  }

  return null as any;
}

export function getGreeting() {
  const hour = DateTime.now().hour;

  switch (true) {
    case hour >= 0 && hour < 6:
      return "Доброй ночи";
    case hour >= 6 && hour < 12:
      return "Доброе утро";
    case hour >= 18 && hour < 24:
      return "Добрый вечер";
    default:
      return "Добрый день";
  }
}

interface DateInRange {
  readonly date: DateTime;
  readonly endDate?: DateTime;
  readonly startDate?: DateTime;
}

export function dateInRange({ date, endDate, startDate }: DateInRange): boolean {
  if (startDate) {
    const startDateMillis = startDate.startOf("day").toMillis();

    if (!endDate) {
      return startDateMillis <= date.startOf("day").toMillis();
    }

    const dateMillis = date.toMillis();
    const endDateMillis = endDate.endOf("day").toMillis();

    return startDateMillis <= dateMillis && endDateMillis >= dateMillis;
  } else if (endDate) {
    const dateMillis = date.startOf("day").toMillis();
    const endDateMillis = endDate.startOf("day").toMillis();

    return endDateMillis === dateMillis;
  }

  return true;
}

export function sameDate(date1: DateTime, date2: DateTime) {
  return date1.startOf("day").toMillis() === date2.startOf("day").toMillis();
}

export function earlier(date1: DateTime, date2: DateTime) {
  return date1.startOf("day").toMillis() < date2.startOf("day").toMillis();
}

export function later(date1: DateTime, date2: DateTime) {
  return date1.startOf("day").toMillis() > date2.startOf("day").toMillis();
}
