import { Box, Stack } from "@mui/material";
import { Image } from "../components/ui/Image";
import NoInternetConnectionIcon_light from "../components/icons/NoInternetConnectionIcon_light.png";
import NoInternetConnectionIcon_dark from "../components/icons/NoInternetConnectionIcon_dark.png";
import { makeStyles, useTheme } from "../theme/Theme";
import Typography from "@mui/material/Typography";
import { ActionButton } from "../components/ui/ActionButton";
import { Breakpoints } from "../dto/ApplicationDTO";
import { useBreakpointValue } from "../hooks/useBreakpointValue";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "25px",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    background: theme.palette.arctic.main,
  },
  btn: {
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginTop: "20px !important",
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginTop: "24px !important",
    },
    width: "310px",
  },
  image: {
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginBottom: "40px",
      width: "261.19px",
      height: "233.9px",
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      width: "320.3px",
      height: "286.83px",
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginBottom: "48px",
      width: "357.1px",
      height: "319.78px",
    },
    [theme.breakpoints.up(Breakpoints.Desktop)]: {
      width: "441.87px",
      height: "395.7px",
    },
  },
}));
export const NoInternetConnection = () => {
  const textDescription = useBreakpointValue(
    {
      upXs: `Медленное подключение к интернету или \nего отсутствие. Пожалуйста, проверьте \nнастройки вашего Интернета или повторите попытку`,
      upSm: `Медленное подключение к интернету или его отсутствие. \n Пожалуйста, проверьте настройки вашего Интернета или повторите попытку`,
      upMd: `Медленное подключение к интернету или его отсутствие. \n Пожалуйста, проверьте настройки вашего Интернета или повторите попытку`,
      upLg: `Медленное подключение к интернету или его отсутствие. \n Пожалуйста, проверьте настройки вашего Интернета или повторите попытку`,
    },
    `Медленное подключение к интернету или его отсутствие. \n Пожалуйста, проверьте настройки вашего Интернета или повторите попытку`,
  );
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";
  const styles = useStyles();
  const source_image = isDarkTheme ? NoInternetConnectionIcon_dark : NoInternetConnectionIcon_light;
  return (
    <Box className={styles.root}>
      <Image className={styles.image} src={source_image} />
      <Stack>
        <Typography marginBottom={"8px"} variant={"h1"}>
          Соединение прервано
        </Typography>
        <Typography
          style={{
            whiteSpace: "pre-line",
          }}
          color={theme.palette.cow.main}
          variant={"text2"}
        >
          {textDescription}
        </Typography>
      </Stack>
      <ActionButton className={styles.btn}>
        <Typography variant={"button"}>Обновить</Typography>
      </ActionButton>
    </Box>
  );
};
