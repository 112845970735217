import cx from "classnames";
import React, { ReactNode } from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";

import { makeStyles } from "../../theme/Theme";
import { PopoverArrowIcon } from "../icons/PopoverArrowIcon";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      padding: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(-16),
      marginLeft: theme.typography.pxToRem(-8),
      backgroundColor: theme.palette.common.transparent,
    },
  },
  root: {
    position: "relative",
    filter: `drop-shadow(${theme.customShadows.menu})`,
  },
  arrow: {
    position: "absolute",
    top: theme.typography.pxToRem(5),
    left: theme.typography.pxToRem(-10),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.typography.pxToRem(0),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.walter.main,
  },
}));

export interface PopupButtonPopoverProps extends Omit<PopoverProps, "children"> {
  readonly content: ReactNode;
  readonly className?: string;
  readonly withArrow?: boolean;
  readonly contentClassName?: string;
}

export function PopupButtonPopover({
  content,
  className,
  withArrow = true,
  PaperProps,
  contentClassName,
  ...props
}: PopupButtonPopoverProps) {
  const classes = useStyles();

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
      PaperProps={{
        elevation: 0,
        ...PaperProps,
        className: cx(classes.paper, PaperProps?.className),
      }}
      sx={PaperProps?.sx}
    >
      <div className={cx(classes.root, className)}>
        {withArrow && <PopoverArrowIcon className={classes.arrow} />}
        <div className={cx(classes.content, contentClassName)}>{content}</div>
      </div>
    </Popover>
  );
}
