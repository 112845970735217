import { isValidElement } from "react";
import { Box, Stack, SxProps } from "@mui/material";

import { Backdrop, BackdropProps } from "./Backdrop";
import { useIsTabletUp, useTheme } from "../../theme/Theme";
import { SidebarBase, SidebarBaseProps } from "./SidebarBase";
import { SidebarHeader, SidebarHeaderProps } from "./SidebarHeader";

interface Props
  extends Pick<SidebarBaseProps, "open" | "outsideClickClose">,
    Pick<SidebarHeaderProps, "title" | "subTitle">,
    Pick<BackdropProps, "children"> {
  readonly onClose: () => void;
  readonly showCloseButton?: boolean;
  readonly headerSx?: SxProps;
}

export function Sidebar({
  open,
  title,
  onClose,
  children,
  subTitle,
  showCloseButton = true,
  outsideClickClose,
  headerSx,
  ...props
}: Props) {
  const theme = useTheme();
  const isSmallUp = useIsTabletUp();

  if (isSmallUp) {
    return (
      <SidebarBase open={open} onClose={onClose} {...props} outsideClickClose={outsideClickClose}>
        <Box maxWidth={theme.typography.pxToRem(365)}>
          <SidebarHeader
            title={title}
            sx={{ mb: "25px", ...headerSx }}
            subTitle={subTitle}
            onCloseClick={showCloseButton ? onClose : undefined}
          />

          <Stack sx={{ width: "calc(100% - 55px)" }}>
            {typeof children === "function" && children({ onClose })}
            {isValidElement(children) && children}
          </Stack>
        </Box>
      </SidebarBase>
    );
  }

  return (
    <Backdrop outsideClickClose={outsideClickClose} onRequestClose={onClose} show={open}>
      {(backdropProps) => (
        <Box
          paddingX={theme.typography.pxToRem(20)}
          paddingTop={theme.typography.pxToRem(20)}
          paddingBottom={theme.typography.pxToRem(60)}
        >
          <SidebarHeader
            title={title}
            sx={{ mb: "25px", ...headerSx }}
            subTitle={subTitle}
            onCloseClick={showCloseButton ? backdropProps.onClose : undefined}
          />
          <Stack sx={{ width: "100%" }}>
            {typeof children === "function" && children(backdropProps)}
            {isValidElement(children) && children}
          </Stack>
        </Box>
      )}
    </Backdrop>
  );
}
