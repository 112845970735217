import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fill: "none", ...props.sx }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.82382C6.93202 2.82382 2.82357 6.93226 2.82357 12.0003C2.82357 17.0683 6.93202 21.1768 12 21.1768C17.0681 21.1768 21.1765 17.0683 21.1765 12.0003C21.1765 6.93226 17.0681 2.82382 12 2.82382Z"
        stroke="#F57B51"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.47061L12.0001 12"
        stroke="#F57B51"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 14.647L12.0001 15.5293"
        stroke="#F57B51"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
