import qs, { ParsedQs } from "qs";
import { useLocation, useNavigate } from "react-router-dom";

type QueryType<T> = ParsedQs & T;
type UpdateFunctionType<T> = (values: T) => void;

export function useQuery<T = unknown>(initial?: Partial<T>): [QueryType<T>, UpdateFunctionType<T>] {
  const location = useLocation();
  const navigate = useNavigate();

  const query = qs.parse(location.search.replace("?", "")) as QueryType<T>;

  const updateHandler = (values: T) => {
    navigate(
      {
        ...location,
        search: qs.stringify({
          ...query,
          ...values,
        }),
      },
      { replace: true },
    );
  };

  return [{ ...initial, ...query }, updateHandler];
}
