import React from "react";
import cx from "classnames";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";

import { makeStyles } from "../../theme/Theme";
import { formatHexToRGBA } from "../../utils/FormatUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    paddingTop: theme.typography.pxToRem(1),
    paddingBottom: theme.typography.pxToRem(1),
  },
  button: {
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.back40.main,
    },
    "&.MuiButtonBase-root": {
      flex: 1,
      maxWidth: theme.typography.pxToRem(36),
      minWidth: theme.typography.pxToRem(36),
      maxHeight: theme.typography.pxToRem(36),
      minHeight: theme.typography.pxToRem(36),
      marginLeft: theme.typography.pxToRem(5),
      marginRight: theme.typography.pxToRem(5),
      borderRadius: theme.typography.pxToRem(16),
      color: formatHexToRGBA({ color: theme.palette.total.main, alpha: 0.1 }),
    },
  },
  buttonCurrentMonth: {
    "&.MuiButtonBase-root": {
      color: theme.palette.total.main,
    },
  },
  buttonActive: {
    opacity: 0.1,
    "&.MuiButtonBase-root": {
      color: theme.palette.const.main,
      backgroundColor: theme.palette.bloom.main,
    },
  },
  buttonActiveCurrentMonth: {
    opacity: 1,
  },
  buttonDisabled: {
    "&.MuiButtonBase-root": {
      color: formatHexToRGBA({ color: theme.palette.gary.main, alpha: 0.6 }),
    },
  },
}));

interface Props extends Omit<ButtonBaseProps, "children"> {
  readonly date: DateTime;
  readonly isActive: boolean;
  readonly isActiveMonthDay: boolean;
}

export function DatePickerCell({ date, isActive, isActiveMonthDay, ...props }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonBase
        {...props}
        className={cx(classes.button, {
          [classes.buttonDisabled]: props.disabled && isActiveMonthDay,
          [classes.buttonCurrentMonth]: isActiveMonthDay,
          [classes.buttonActive]: isActive && !props.disabled,
          [classes.buttonActiveCurrentMonth]: isActiveMonthDay && isActive,
        })}
      >
        <Typography variant="calendar">{date.day}</Typography>
      </ButtonBase>
    </div>
  );
}
