import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store/configureStore";
import {
  NotificationContextProps,
  SnackbarInitialState,
  SnackbarProps,
} from "../components/ui/Snackbar";
import { getErrorTextFromError } from "../utils";

const initialState: Omit<NotificationContextProps, "closeSnackbar" | "openSnackbar"> = {
  content: "",
  open: false,
  title: "",
  notificationState: SnackbarInitialState,
};
type ErrorSnackBar = any | undefined;

export const notificationSlice = createSlice({
  initialState,
  name: "notification",
  reducers: {
    openSnackbarActions: (state, payload: PayloadAction<SnackbarProps>) => {
      state.notificationState = { ...payload.payload, open: true };
    },
    closeSnackbarActions: (state) => {
      state.notificationState = { ...state.notificationState, open: false };
    },
    openSnackbarErrorActions: (state, payload: PayloadAction<ErrorSnackBar>) => {
      const errorText = getErrorTextFromError(payload.payload) || "Пожалуйста, попробуйте ещё раз.";
      state.notificationState = {
        content: errorText,
        title: "Что-то пошло не так ...",
        open: true,
      };
    },
  },
});

/**
 * Actions
 * */
export const { closeSnackbarActions, openSnackbarActions, openSnackbarErrorActions } =
  notificationSlice.actions;

export const notificationSelector = (state: RootState) => state.notification.notificationState;

/**
 * Reducer
 * */
export default notificationSlice.reducer;
