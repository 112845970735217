export enum PassportStatus {
  Valid = "valid",
  Expired = "expired",
  Invalid = "invalid",
}

export enum TaxStatus {
  Resident = "resident",
  NonResident = "non-resident",
  Verification = "verification",
}

export enum ChangeEmailReason {
  Sms = "sms",
  Email = "email",
  Change = "change",
  Google = "google",
}

export enum ChangePhoneReason {
  Sms = "sms",
  Email = "email",
  Change = "change",
  Google = "google",
}

export enum TwoFactorSmsReason {
  Change = "change",
  Google = "google",
  Disable = "disable",
}

export type StatusUserInSystem = "cold";
