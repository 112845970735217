/**
 * NotStarted - Начальное. приложение только открылось. Показывается сплеш скрин
 * Loading - Устанавливается после проверки наличия интернета, если интернет есть. начинается загрузка данных на сплеш скрине
 * Started - Устанавливается после сплеш скрина, переход на авторизацию
 * Authorized - Устанавливается после успешной авторизации, работа с приложением
 * Undetermined - Не прошла инициализация приложения
 * */
export enum AppState {
  Loading = "loading",
  Started = "started",
  Authorized = "authorized",
  NotStarted = "not-started",
  Undetermined = "undetermined",
}

/**
 * Connected - есть соединение
 * Disconnected - соединение отсутствует
 * Undetermined - состояние соединения еще неизвестно
 */
export enum ConnectionState {
  Connected = "connected",
  Disconnected = "disconnected",
  Undetermined = "undetermined",
}

export enum AppEnvironment {
  Staging = "staging",
  Production = "production",
  Development = "development",
}

export enum FontType {
  MuseoSansCyrl = "MuseoSansCyrl",
}

export enum FontStyle {
  Light = "300",
  Medium = "500",
  Bold = "700",
  Black = "900",
}

export enum Breakpoints {
  Mobile = "xs",
  Tablet = "sm",
  Laptop = "md",
  Desktop = "lg",
}

export enum ResponseCode {
  Success,
  Error,
  BadRequest,
  Unauthorized,
  Unknown = 999,
  ErrorManyRequest = 17,
}

export enum Direction {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right",
}

export enum HttpMethod {
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Delete = "DELETE",
}

export enum ThemeMode {
  Dark = "dark",
  Light = "light",
  System = "system",
}
