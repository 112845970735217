import cx from "classnames";
import React, { forwardRef } from "react";
import { BoxProps, Box as MuiBox } from "@mui/material";
import { makeStyles } from "../../theme/Theme";

interface Props extends BoxProps {
  clickable?: boolean;
  disabled?: boolean;
}

const useClickableStyles = makeStyles<{ disabled?: boolean }>(() => ({
  clickable: {
    cursor: (props) => (props.disabled ? "default" : "pointer"),
    WebkitTapHighlightColor: "transparent",
    textDecoration: "none",
    userSelect: "none",
    appearance: "none",
  },
}));

export const Box = forwardRef(({ clickable, disabled, className, ...other }: Props, ref) => {
  const clickableClasses = useClickableStyles({ disabled });

  return (
    <MuiBox
      ref={ref}
      className={cx(clickable && clickableClasses.clickable, className)}
      {...other}
    />
  );
});
