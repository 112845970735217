import { noop } from "lodash";
import React, { ReactNode, Reducer, useCallback, useContext, useEffect, useReducer } from "react";

import { calculatePrice } from "../../helpers/PriceHelpers";
import { isAuthorizedSelector } from "../../slices/authSlice";
import { useLazyCurrencyListQuery } from "../../api/commonApi";
import { DEFAULT_CURRENCY } from "../../constants/CurrencyConstants";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { settingsCurrencySelector } from "../../api/settingsApi";

interface ContextStateProps {
  readonly currencyList: Currency.Props[];
  readonly activeCurrency: Currency.Props;
}

interface ContextMethodsProps {
  readonly setActiveCurrency: (currency: Currency.Props) => void;
  readonly calculateAmount: (amount: number, prefix?: string) => string;
}

type ContextProps = ContextStateProps & ContextMethodsProps;

interface Props {
  readonly children: ReactNode;
}

enum ReducerActions {
  SetCurrencyList = "Currency/SetCurrencyList",
  SetActiveCurrency = "Currency/SetActiveCurrency",
}

function reducer(state: ContextStateProps, action: Application.ContextAction<ReducerActions>) {
  switch (action.type) {
    case ReducerActions.SetActiveCurrency:
      return { ...state, activeCurrency: action.payload };

    case ReducerActions.SetCurrencyList:
      return { ...state, currencyList: action.payload };

    default:
      return state;
  }
}

const initialValues: ContextStateProps = {
  currencyList: [],
  activeCurrency: DEFAULT_CURRENCY,
};

function createContentValue(): ContextProps {
  return {
    ...initialValues,

    setActiveCurrency: noop,
    calculateAmount: () => "",
  };
}

export const CurrencyContext = React.createContext<ContextProps>(createContentValue());

export function CurrencyProvider(props: Props) {
  const settingsCurrency = useShallowEqualSelector(settingsCurrencySelector);
  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  const [state, dispatch] = useReducer<
    Reducer<ContextStateProps, Application.ContextAction<ReducerActions>>
  >(reducer, initialValues);

  const [listQuery] = useLazyCurrencyListQuery();
  const setActiveCurrencyHandler = useCallback(
    (payload: Currency.Props) => dispatch({ type: ReducerActions.SetActiveCurrency, payload }),
    [],
  );

  const calculateAmountHandler = useCallback(
    (amount: number, prefix?: string) => {
      const calculatedPrice = calculatePrice(amount, state.activeCurrency);

      return [prefix, calculatedPrice].filter(Boolean).join("");
    },
    [state.activeCurrency],
  );

  useEffect(() => {
    setActiveCurrencyHandler(settingsCurrency);
  }, [settingsCurrency, setActiveCurrencyHandler]);

  useEffect(() => {
    if (isAuthorized) {
      listQuery()
        .then((response) => {
          if (response.data?.data) {
            dispatch({ type: ReducerActions.SetCurrencyList, payload: response.data.data });
          }
        })
        .catch(noop);
    }
  }, [isAuthorized, listQuery]);

  return (
    <CurrencyContext.Provider
      {...props}
      value={{
        ...state,
        calculateAmount: calculateAmountHandler,
        setActiveCurrency: setActiveCurrencyHandler,
      }}
    />
  );
}

export function useCurrencyContext(): ContextProps {
  return useContext(CurrencyContext);
}
