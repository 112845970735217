import { useMemo, useState } from "react";
import {
  useRegistrationBuyStrategyMutation,
  useRegistrationConfirmEmailMutation,
  useRegistrationConfirmMutation,
  useRegistrationGenerateMutation,
  useRegistrationSaveDataMutation,
} from "../api/authApi";
import { useAuth } from "../components/auth/AuthContext";
import { TextInputProps } from "../components/ui/TextInput";
import { Auth } from "../types/app/auth";
import { isValidEmail, normalizePhone } from "../utils";
import { changeModeUser } from "../slices/applicationSlice";
import { useDispatch } from "react-redux";
import { openSnackbarError } from "../utils/NotificationsUtils";

export interface StepDataFieldsValid {
  len?: number;
  validFn?: (value: string) => boolean;
  required?: boolean;
}

interface StepDataType {
  title: string;
  formTitle: string;
  fields: Array<keyof Auth.UserInfoSave>;
  fieldsValid?: Partial<Record<keyof Auth.UserInfoSave, StepDataFieldsValid>>;
  optionsFields?: Partial<Record<keyof Auth.UserInfoSave, TextInputProps>>;
  stepNumber: number;
}

export const placeholderRegistrationFields: Record<keyof Auth.UserInfoSave, string> = {
  name: "Имя",
  email: "Почта",
  patronymic: "Отчество",
  surname: "Фамилия",
};
export const stepDataRegistrations: StepDataType[] = [
  {
    stepNumber: 1,
    fields: ["surname", "name", "patronymic"],
    formTitle: "Персональные данные",
    optionsFields: {
      name: { placeholder: "Имя" },
      surname: { placeholder: "Фамилия" },
      patronymic: { placeholder: "Отчество (если есть)" },
    },
    fieldsValid: {
      name: { required: true },
      surname: { required: true },
      patronymic: { required: false },
    },
    title:
      "Заполните в соответствие с паспортными данными. Эта информация очень важна для продолжения. ",
  },
  {
    stepNumber: 2,
    fields: ["email"],
    formTitle: "Контактные данные",
    fieldsValid: {
      email: {
        validFn: isValidEmail,
        required: true,
      },
    },
    optionsFields: {
      email: {
        placeholder: "Email",
      },
    },
    title: `Пожалуйста, укажите ваш электронный адрес. Он понадобится для отправки договора.\nНа указанную почту мы отправим код для подтверждения.`,
  },
];

export const useRegistration = () => {
  const dispatch = useDispatch();

  const [saveDataApi] = useRegistrationSaveDataMutation();
  const [confirmEmailApi] = useRegistrationConfirmEmailMutation();
  const [generate] = useRegistrationGenerateMutation();
  const [confirmApi] = useRegistrationConfirmMutation();
  const [buyStrategyApi] = useRegistrationBuyStrategyMutation();
  const {
    step,
    changeStep,
    changeDataUser,
    clearDataUser,
    dataUser,
    setIsOpenConfirmMail,
    isOpenConfirmMail,
    setRegPhone,
    regPhone,
  } = useAuth();

  const [strategyBuyId, setStrategyBuyId] = useState<string | null>(null);
  const nextStep = () => {
    if (step === stepDataRegistrations.length) {
      submitUserData().catch(openSnackbarError);
      setIsOpenConfirmMail(true);
      return;
    }
    changeStep(step + 1);
  };

  const prevStep = () => {
    if (step === 1) {
      clearDataUser();
      setRegPhone(undefined);
    }
    if (step === 0) return;
    changeStep(step - 1);
  };
  const changeEmailAgain = () => {
    changeStep(stepDataRegistrations.length);
  };
  const confirmEmail = (data: Auth.ConfirmEmail) => {
    return confirmEmailApi(data)
      .unwrap()
      .then(() => {
        changeStep(0);
      });
  };
  const confirmRegistrationUser = (data: { code: string }) => {
    return confirmApi(data);
  };
  const saveData = (data: Partial<Auth.UserInfoSave>) => {
    return saveDataApi(data as Auth.UserInfoSave).unwrap();
  };
  const submitUserData = () => {
    if (!dataUser) return Promise.reject(new Error("Данные незаполненные"));
    return saveData(dataUser);
  };
  const buyStrategy = () => {
    if (!strategyBuyId) return;
    return buyStrategyApi({ strategyId: strategyBuyId.toString() });
  };

  const currentStepData = useMemo(() => {
    return (
      stepDataRegistrations.find((stepItem) => stepItem.stepNumber === step) ||
      stepDataRegistrations[0]
    );
  }, [step]);
  const beginInputPersonalData = (phone: string) => {
    changeStep(1);
    setRegPhone(phone);
  };
  const resetData = () => {
    dispatch(changeModeUser("simple"));
    changeStep(0);
    clearDataUser();
    setIsOpenConfirmMail(false);
  };
  return {
    nextStep,
    prevStep,
    submitUserData,
    generateSmsSavePersonalData: generate,
    setIsOpenConfirmMail,
    setStrategyBuyId,
    beginInputPersonalData,
    resetData,

    buyStrategy,
    changeEmailAgain,
    confirmRegistrationUser,
    confirmEmail,
    changeDataUser,

    strategyBuyId,
    isOpenConfirmMail,
    dataUser,
    regPhone,
    regPhoneNotMasked: normalizePhone(regPhone),
    currentStepData,
    stepRegistration: step,
  };
};
