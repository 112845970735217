import { Stack, Typography } from "@mui/material";
import { formatResendButtonTitle, hidePhoneNumbers } from "../../helpers/FormatHelpers";
import { makeStyles, useTheme } from "../../theme/Theme";
import { maskPhoneNumber } from "../../utils";
import { ActionButton, ButtonType } from "../ui/ActionButton";
import { useResendCode } from "../../hooks/useResendCode";
import { CodeField } from "../form/CodeField";
import { Formik } from "formik";
import { ReactNode, useMemo, useState } from "react";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { Variant } from "@mui/material/styles";
import { openSnackbarError } from "../../utils/NotificationsUtils";

export interface SubmitCodeError {
  readonly title: string;
  readonly message?: string;
}

export interface SubmitCodeProps {
  readonly onResend: () => Promise<void>;
  readonly onSubmit: (code: string) => Promise<any>;
}

interface Props extends Omit<SubmitCodeProps, "onResend"> {
  readonly email?: string;
  readonly phoneNumber?: string;
  readonly onCloseClick?: () => void;
  readonly title: string | ReactNode;
  readonly text?: string;
  readonly resendAvailable?: boolean;
  onResend?: () => Promise<void>;
  textVariant?: Variant;
}

const useStyles = makeStyles((theme) => ({
  codeFields: {
    marginTop: "30px !important",
  },
}));

export function ProfileSubmitCode({
  phoneNumber,
  onCloseClick,
  onResend,
  onSubmit,
  title,
  textVariant = "text2",
  text,
  email,
  resendAvailable,
}: Props) {
  const theme = useTheme();
  const [limitExceeded, setLimitExceeded] = useState(false);
  const styles = useStyles();
  const [time, resendCode] = useResendCode();

  const buttonTitle = formatResendButtonTitle(time, {
    formatWaitTitle: (x) => `Новый код через ${x}`,
  });

  const phone = useMemo(() => {
    if (phoneNumber) {
      return hidePhoneNumbers(maskPhoneNumber(phoneNumber));
    }
  }, [phoneNumber]);

  const subText = email ?? phone;
  return (
    <Stack spacing={3}>
      <SidebarHeader
        title={title}
        onCloseClick={onCloseClick}
        subTitle={
          <Typography variant={textVariant}>
            {`${text} `}
            {subText && subText}
          </Typography>
        }
      />

      <Formik
        onSubmit={async (values, helpers) => {
          return onSubmit(values.code).catch((error) => {
            if (error.data?.code === 999) {
              setLimitExceeded(true);
            } else {
              helpers.setFieldError("code", error.message);
            }
          });
        }}
        initialValues={{ code: "" }}
      >
        {({ errors, isSubmitting, handleSubmit, setSubmitting }) => {
          const resendHandler = async () => {
            setSubmitting(true);
            if (!onResend) {
              return Promise.reject();
            }
            return onResend()
              .then(() => resendCode.reset())
              .catch(openSnackbarError)
              .finally(() => setSubmitting(false));
          };

          const isShowActionButton = resendAvailable && !limitExceeded && onResend;

          return (
            <>
              <CodeField
                name="code"
                className={styles.codeFields}
                showErrors={true}
                isLoad={!isShowActionButton && isSubmitting}
                inputProps={{
                  autoFocus: true,
                  width: ["100%", theme.pxToRem(310)],
                  disabled: !!limitExceeded || isSubmitting,
                  onComplete: () => handleSubmit(),
                }}
              />

              {limitExceeded && (
                <Stack alignItems="center">
                  <Typography color={theme.palette.sparrow.main}>
                    Превышено количество запросов
                  </Typography>
                  <Typography sx={{ opacity: 0.4 }} color={theme.palette.total.main}>
                    Вы сможете продолжить через 10 минут
                  </Typography>
                </Stack>
              )}
              {isShowActionButton && (
                <ActionButton
                  sx={{
                    maxWidth: ["100%", theme.typography.pxToRem(310)],
                    width: "100%",
                    marginTop: errors.code ? theme.typography.pxToRem(20) : 0,
                  }}
                  buttonType={ButtonType.Primary}
                  onClick={resendHandler}
                  loading={isSubmitting}
                  disabled={time > 0 && !isSubmitting}
                >
                  <Typography>{buttonTitle}</Typography>
                </ActionButton>
              )}
            </>
          );
        }}
      </Formik>
    </Stack>
  );
}
