import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { ApiMethod, fetchQuery } from "../helpers/ApiHelpers";

export const surveyApi = createApi({
  reducerPath: "surveyApi",
  baseQuery: fetchQuery(),
  endpoints: (builder) => ({
    generateSurveyCode: builder.mutation<void, void>({
      query: () => ApiRoutes.SurveyGenerateCode,
    }),
    getSurveyAnswers: builder.query<
      Application.ResponseProps<Survey.AnswerPrev[]>,
      { scenario: Survey.ScenarioType }
    >({
      query: (args) => {
        return createUrl(ApiRoutes.SyrveyPreviousAnswers, { query: { scenario: args.scenario } });
      },
    }),
    getSurveyData: builder.query<
      Application.ResponseProps<Survey.FormProps>,
      { scenario: Survey.ScenarioType }
    >({
      query: (args) => {
        return createUrl(ApiRoutes.SurveyData, { query: { scenario: args.scenario } });
      },
    }),
    sendSurveyData: builder.mutation<void, Survey.SendSurveyItemsQueryProps>({
      query: (body) => ({
        url: ApiRoutes.SurveyData,
        method: ApiMethod.Post,
        body: body,
      }),
    }),
  }),
});

export const {
  useGetSurveyDataQuery,
  useLazyGetSurveyAnswersQuery,
  useSendSurveyDataMutation,
  useGenerateSurveyCodeMutation,
} = surveyApi;
