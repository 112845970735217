import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { formatResendButtonTitle, hidePhoneNumbers } from "../../helpers/FormatHelpers";
import { useResendCode } from "../../hooks/useResendCode";
import { useTheme } from "../../theme/Theme";
import { maskPhoneNumber } from "../../utils";
import { ActionButton, ButtonType } from "../ui/ActionButton";
import { CodeInput } from "../ui/CodeInput";
import { CloseIcon } from "../icons/CloseIcon";
import { openSnackbarError } from "../../utils/NotificationsUtils";

interface Props {
  readonly phoneNumber: string;
  readonly onSubmit: (code: string) => Promise<void>;
  readonly onResend: () => Promise<void>;
  onClose?: () => void;
}

export function ManageFundsSubmitCode({ phoneNumber, onSubmit, onClose, onResend }: Props) {
  const theme = useTheme();

  const [value, setValue] = useState("");
  const [time, resendCode] = useResendCode();
  const [loading, setLoading] = useState(false);

  const buttonTitle = formatResendButtonTitle(time, {
    formatWaitTitle: (x) => `Новый код через ${x}`,
  });

  const resendHandler = async () => {
    setLoading(true);
    return onResend()
      .then(() => resendCode.reset())
      .catch(openSnackbarError)
      .finally(() => setLoading(false));
  };

  const confirmHandler = async () => {
    setLoading(true);
    return onSubmit(value)
      .catch(openSnackbarError)
      .finally(() => setLoading(false));
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <Stack direction="column">
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"self-start"}>
        <Box>
          <Typography
            mb={theme.typography.pxToRem(15)}
            variant="h1"
            color={theme.palette.total.main}
          >
            Подтверждение
          </Typography>
          <Typography variant="input2" color={theme.palette.total.main}>
            Мы отправили СМС на Ваш номер {hidePhoneNumbers(maskPhoneNumber(phoneNumber))}
          </Typography>
        </Box>
        {onClose && (
          <IconButton sx={{ p: 0, ml: "32px" }} onClick={onClose}>
            <CloseIcon size={30} />
          </IconButton>
        )}
      </Stack>
      <CodeInput
        sx={{
          marginTop: "30px",
        }}
        maxWidth={theme.typography.pxToRem(367)}
        onChange={handleChange}
        autoFocus={true}
      />
      {value.length === 6 ? (
        <ActionButton
          sx={{
            maxWidth: theme.typography.pxToRem(310),
            width: "100%",
            marginTop: theme.typography.pxToRem(40),
          }}
          buttonType={ButtonType.Primary}
          onClick={confirmHandler}
          loading={loading}
        >
          <Typography variant={"button"}>Подтвердить</Typography>
        </ActionButton>
      ) : (
        <ActionButton
          sx={{
            maxWidth: theme.typography.pxToRem(310),
            width: "100%",
            marginTop: theme.typography.pxToRem(40),
          }}
          buttonType={ButtonType.Primary}
          onClick={resendHandler}
          loading={loading}
          disabled={time > 0}
        >
          <Typography variant={"button"}>{buttonTitle}</Typography>
        </ActionButton>
      )}
    </Stack>
  );
}
