import { CSSProperties } from "react";
import { isInvalidPassportStatus, isPassportSoonBeExpired } from "../../helpers/ProfileHelpers";
import { dateToFormat, SHORT_DMY_DATE_FORMAT } from "../../utils";
import { Alert } from "../ui/Alert";

interface EligibleForWithdrawalDto {
  readonly canWithdraw: boolean;
  readonly passportUpdateRequired: boolean;
}

export function isEligibleForWithdrawal(
  passport?: Profile.PassportProps,
): EligibleForWithdrawalDto {
  if (!passport) {
    return {
      canWithdraw: false,
      passportUpdateRequired: false,
    };
  }
  const isInvalid = isInvalidPassportStatus(passport);

  if (isInvalid || isPassportSoonBeExpired(passport, 0)) {
    return {
      canWithdraw: false,
      passportUpdateRequired: true,
    };
  }

  if (isPassportSoonBeExpired(passport, 5)) {
    return {
      canWithdraw: true,
      passportUpdateRequired: true,
    };
  }

  return {
    canWithdraw: true,
    passportUpdateRequired: false,
  };
}

interface Props {
  readonly passport?: Profile.PassportProps;
  readonly sx?: CSSProperties;
}

export function ManageFundsInvalidPassportError({ passport, sx }: Props) {
  if (!passport) {
    return null;
  }

  const { canWithdraw, passportUpdateRequired } = isEligibleForWithdrawal(passport);
  if (!canWithdraw) {
    return (
      <Alert
        sx={sx}
        text="Ваши паспортные данные необходимо обновить. Вы не можете выводить средства"
      />
    );
  }

  if (passportUpdateRequired) {
    const date = dateToFormat({
      format: SHORT_DMY_DATE_FORMAT,
      date: passport.expirationDate,
    });

    return (
      <Alert
        sx={sx}
        text={`Пожалуйста, обновите паспортные данные в связи с оканчивающимся сроком действия. \nС ${date} Вы не сможете выводить средства.`}
      />
    );
  }

  return null;
}
