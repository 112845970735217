import { useEffect, useState } from "react";

const INITIAL_TIME = 59;

interface Options {
  readonly initial?: number;
}

interface ReturnProps {
  readonly reset: () => void;
}

export function useResendCode(options: Options = {}): [number, ReturnProps] {
  const { initial = INITIAL_TIME } = options;

  const [time, setTime] = useState(initial);

  const resetHandler = () => setTime(initial);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setTime((x) => {
          if (x > 0) {
            return x - 1;
          }

          return x;
        }),
      1000,
    );

    return () => {
      setTime(initial);
      clearInterval(interval);
    };
  }, [initial]);

  return [time, { reset: resetHandler }];
}
