import cx from "classnames";
import React, { useMemo } from "react";

import { makeStyles } from "../../theme/Theme";
import { useChartColors } from "../../hooks/useChartColors";
import { Box, BoxProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 0,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    height: theme.typography.pxToRem(12),
    borderRadius: theme.typography.pxToRem(12),
  },
  line: {
    overflow: "hidden",
    borderRadius: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.arctic.main}`,

    "&:not(:first-child)": {
      marginLeft: "-25%",
    },
  },
}));

interface Props extends BoxProps {
  readonly data: number[];
  readonly isCompare?: boolean;
  readonly customColors?: string[];
}

export function LinearChart({ className, data, isCompare, customColors, ...props }: Props) {
  const total = useMemo(() => data.reduce((acc, item) => acc + item, 0), [data]);

  const classes = useStyles();

  const baseColors = useChartColors();

  const colors = customColors || baseColors;

  return (
    <Box className={cx(classes.root, className)} {...props}>
      {data.map((x, idx, arr) => {
        const width = 100 / (total / x);
        const lineWidth = idx === 0 ? width : width + 25;

        return (
          <div
            key={idx}
            className={classes.line}
            style={{
              width: `${lineWidth}%`,
              zIndex: arr.length - idx,
              backgroundColor: colors[idx],
              borderColor: isCompare ? colors[idx] : undefined,
            }}
          />
        );
      })}
    </Box>
  );
}
