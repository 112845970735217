import { Stack } from "@mui/material";
import { Sidebar } from "../sidebar/Sidebar";
import { useRegistration } from "../../hooks/useRegistration";
import { CodeInput } from "../ui/CodeInput";
import { ActionButton, ButtonType } from "../ui/ActionButton";
import { formatResendButtonTitle } from "../../helpers/FormatHelpers";
import { useResendCode } from "../../hooks/useResendCode";
import { Spacer } from "../ui/Spacer";
import { Auth } from "../../types/app/auth";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { useEffect, useState } from "react";
import { createUrl } from "../../utils";
import { useLazyRegistrationInfoUserQuery } from "../../api/authApi";
import { changeModeUser } from "../../slices/applicationSlice";
import { useDispatch } from "react-redux";
import { openSnackbarError } from "../../utils/NotificationsUtils";

export const AuthSidebarConfirmEmail = () => {
  const {
    dataUser,
    submitUserData,
    changeEmailAgain,
    isOpenConfirmMail,
    regPhoneNotMasked,
    setIsOpenConfirmMail,
    confirmEmail,
    resetData,
  } = useRegistration();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [time, resendCode] = useResendCode();
  const [registrationInfoUser, { isLoading: isLoadingInfoUser }] =
    useLazyRegistrationInfoUserQuery();

  const isLoadSend = isLoadingInfoUser || isLoading;

  const buttonTitle = formatResendButtonTitle(time, {
    formatWaitTitle: (x) => `Новый код через ${x}`,
  });
  const resendHandler = () => {
    submitUserData()
      .finally(() => {
        resendCode.reset();
      })
      .catch(openSnackbarError);
  };
  const closeSidebar = () => {
    setIsOpenConfirmMail(false);
  };
  const toHotUserSurvey = (strategyID: string | undefined) => {
    navigate(
      createUrl(AppRoutes.Survey, {
        query: {
          scenario: "PurchasingStrategyDemo",
          strategyId: strategyID,
          isHot: 1,
        },
      }),
    );
  };

  useEffect(() => {
    resendCode.reset();
  }, [isOpenConfirmMail]);

  return (
    <Sidebar
      onClose={closeSidebar}
      title={"Подтвердите почту"}
      open={isOpenConfirmMail}
      subTitle={`Мы отправили письмо с кодом подтверждения на вашу почту: ${dataUser?.email}`}
    >
      {({ onClose }) => {
        const changeEmail = () => {
          changeEmailAgain();
          onClose();
        };
        const onComplete = (data) => {
          setIsLoading(true);
          // eslint-disable-next-line promise/catch-or-return
          return confirmEmail({
            code: data,
            phone: regPhoneNotMasked,
          } as Auth.ConfirmEmail)
            .then(() => {
              registrationInfoUser()
                .unwrap()
                .then((data) => {
                  if (data?.data?.idTargetStrategy) {
                    dispatch(changeModeUser("hot"));
                    toHotUserSurvey(data?.data?.idTargetStrategy);
                  } else {
                    dispatch(changeModeUser("cold"));
                    navigate(AppRoutes.DashboardStrategiesLink, { replace: true });
                  }
                })
                .catch(openSnackbarError);
              resetData();
            })
            .catch(openSnackbarError)
            .finally(() => {
              setIsLoading(false);
            });
        };
        return (
          <Stack>
            <CodeInput isLoad={isLoadSend} onComplete={onComplete} />
            {!isLoadSend && (
              <>
                <Spacer size={20} />
                <ActionButton disabled={time > 0} onClick={resendHandler}>
                  {buttonTitle}
                </ActionButton>
              </>
            )}
            <Spacer size={15} />
            <ActionButton buttonType={ButtonType.Grape} onClick={changeEmail}>
              Изменить почту
            </ActionButton>
          </Stack>
        );
      }}
    </Sidebar>
  );
};
