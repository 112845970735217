import { isString } from "lodash";
import { deepmerge } from "@mui/utils";
import { PropInjector } from "@mui/types";
import { Options } from "@mui/material/useMediaQuery";
import { Theme as MuiTheme, ThemeOptions } from "@mui/material/styles";
import { PaletteMode, useMediaQuery as useMediaQueryMui } from "@mui/material";
import {
  ClassKeyOfStyles,
  ClassNameMap,
  PropsOfStyles,
  StyledComponentProps,
  Styles,
  ThemeOfStyles,
  WithStyles,
  WithStylesOptions,
} from "@mui/styles/withStyles";
import {
  makeStyles as muiMakeStyles,
  useTheme as useMuiTheme,
  withStyles as muiWithStyles,
} from "@mui/styles";

import { createStyled } from "@mui/system";
import { MuiPaper } from "./components/MuiPaper";
import { MuiSwitch } from "./components/MuiSwitch";
import { Breakpoints } from "../dto/ApplicationDTO";
import { MuiSnackbar } from "./components/MuiSnackbar";
import { createMuiTypographyComponent } from "./ThemeComponents";

/* The default font size of the Material Specification. */
const FONT_SIZE = 14;
/* 16px is the default font-size used by browsers. */
const HTML_FONT_SIZE = 16;

/* Following snippet is taken from mui-material library */
const pxToRem = (size: number) => `${((size / HTML_FONT_SIZE) * FONT_SIZE) / 14}rem`;

const breakpointsValues = {
  xs: 0,
  sm: 768,
  md: 1280,
  lg: 1920,
};
export const BaseTheme: ThemeOptions = {
  mixins: {
    flex: { display: "flex" },
    flexFull: { display: "flex", flex: "1 1 100%" },
    flexColumn: { display: "flex", flexDirection: "column" },
    flexCenter: { display: "flex", alignItems: "center", justifyContent: "center" },
  },
  shadows: [
    "none",
    "4px 4px 5px rgba(22, 27, 30, 0.13)",
    "0px 0px 20px rgba(0, 0, 0, 0.1)",
    "0px 0px 1px rgba(0, 0, 0, 0.3)",
    "0px 4px 10px rgba(74, 81, 112, 0.05)",
    "0px 0px 1px rgba(0, 0, 0, 0.3)",
    "inset 0px 4px 4px #636A8B",
  ],
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
      transparent: "rgba(0, 0, 0, 0)",
    },
  },
  pxToRem: pxToRem,
  typography: {
    fontFamily: ["SFPro Display", "sans-serf"].join(", "),
    auth1: {
      fontSize: "80px",
      lineHeight: "88px",
      fontWeight: 510,
    },
    auth2: {
      fontSize: "40px",
      lineHeight: "45px",
      fontWeight: 400,
    },
    auth3: {
      fontSize: "21px",
      lineHeight: "24px",
      fontWeight: 510,
    },
    h0: {
      fontSize: "112px",
      fontWeight: 1000,
      lineHeight: "112px",
    },
    h1: {
      fontSize: "28px",
      lineHeight: "30px",
      fontWeight: 590,
    },
    h2: {
      fontSize: "18px",
      lineHeight: "18px",
      fontWeight: 590,
    },
    h3: {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 300,
    },
    h4: {
      fontSize: "17px",
      lineHeight: "17px",
      fontWeight: 300,
    },
    button: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 510,
      textTransform: "none",
    },
    button1: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 510,
    },
    button2: {
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: 700,
    },
    "button-filled": {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
    },
    button3: {
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: 590,
    },
    input1: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 400,
    },
    input2: {
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: 590,
    },
    link1: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
    },
    text1: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 400,
    },
    text2: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
    },
    text3: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 590,
    },
    text4: {
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: 400,
    },
    text5: {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.01em",
      fontWeight: 400,
    },
    text6: {
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "0.01em",
      fontWeight: 400,
    },
    text7: {
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "0.01em",
      fontWeight: 590,
    },
    balance: {
      fontSize: "28px",
      lineHeight: "30px",
      fontWeight: 590,
    },
    calendar: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 510,
    },
    "calendar-month": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 590,
    },
    "card-primary": {
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: 590,
    },
    "card-secondary": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 590,
    },
    "paper-text": {
      fontSize: "17px",
      lineHeight: "23px",
      fontWeight: 400,
    },
  },
  breakpoints: {
    values: breakpointsValues,
    heightUp: (value: string) => `@media (min-height: ${value})`,
    heightDown: (value: string) => `@media (max-height: ${value})`,
  },
  spacing: [0, 10, 15, 20, 25, 30, 40],
  components: {
    MuiTypography: createMuiTypographyComponent(),
    MuiSnackbar,
    MuiPaper,
    MuiSwitch,
  },
};

export const DarkTheme: ThemeOptions = {
  customShadows: {
    button: "0px 2px 15px rgba(64, 98, 255, 0.51)",
    hover: "0px 0px 10px rgba(64, 98, 255, 0.79)",
    snackbar: "0px 3px 4px rgba(255, 255, 255, 0.08)",
    tap: "inset 0px 4px 4px #314FD9",
    grapeTap: "inset 0px 4px 4px #636A8B",
    menu: "0px 4px 10px rgba(74, 81, 112, 0.05)",
    input: "0px 0px 5px rgba(64, 98, 255, 0.45)",
    baseInput: "0px 0px 1px rgba(0, 0, 0, 0.3)",
    paper_1: "4px 4px 5px rgba(22, 27, 30, 0.13)",
    paper_2: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  background: {
    callbackBannerBack: "linear-gradient(196.91deg, #141518 0%, #020307 88.35%)",
    callbackBannerFront: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 71.13%)",
    callbackBannerFrontSmall: "linear-gradient(180deg, rgba(0, 0, 0, 0) 58.5%, #000000 100%)",
    sidebarHeader: "linear-gradient(180deg, #010101 70.19%, rgba(1, 1, 1, 0) 84.21%)",
    sidebarActions: "linear-gradient(180deg, #010101 76.87%, rgba(1, 1, 1, 0) 100%)",
  },
  palette: {
    mode: "dark",
    background: { default: "#000000" },

    snow: { main: "#111111" },
    arctic: { main: "#0d0d0d" },
    back: { main: "#1f1f1f" },
    back40: { main: "#0a0a0e" },
    grape: { main: "#555a74" },
    grape30: { main: "#1a1a1a" },
    bloom: { main: "#4062ff" },
    lin: { main: "linear-gradient(91.88deg, #00167C -6.12%, #0D0D0D 50.53%)" },
    placeholder: { main: "linear-gradient(91.86deg, #3b3b3b -4.05%, #0d0d0d 50.02%)" },

    const: { main: "#ffffff" },
    walter: { main: "#000000" },
    wall: { main: "#0f0f0f" },
    gary: { main: "#f5f5f5" },
    cow: { main: "#d8d8d8" },
    total: { main: "#f2f2f2" },

    sparrow: { main: "#ff4545" },
    eye: { main: "#4da63e" },

    marian: { main: "#43718d" },
    ocean: { main: "#007dae" },
    pool: { main: "#7eb5c8" },
    bikiniBottom: { main: "#1b969e" },
    wave: { main: "#45968c" },
    icicle: { main: "#85cbb5" },
    plant: { main: "#8bbe68" },
    grass: { main: "#627d41" },
    daicy: { main: "#e0dd70" },
    banana: { main: "#dfc312" },
    icarus: { main: "#dca143" },
    griffin: { main: "#e37b0a" },
    sun: { main: "#d4633b" },
    mary: { main: "#cc5035" },
    tallaringa: { main: "#c26160" },
    california: { main: "#deb29e" },
    fiji: { main: "#d6848e" },
    ametist: { main: "#6f4e71" },
    polumne: { main: "#958393" },
    lavander: { main: "#575e98" },
  },
};
export const LightTheme: ThemeOptions = {
  customShadows: {
    button: "0px 2px 15px rgba(64, 98, 255, 0.51)",
    hover: "0px 0px 10px rgba(64, 98, 255, 0.79)",
    snackbar: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    tap: "inset 0px 4px 4px #314FD9",
    grapeTap: "inset 0px 4px 4px #636A8B",
    menu: "0px 4px 10px rgba(74, 81, 112, 0.05)",
    input: "0px 0px 5px rgba(64, 98, 255, 0.45)",
    baseInput: "0px 0px 1px rgba(0, 0, 0, 0.3)",
    paper_1: "4px 4px 5px rgba(22, 27, 30, 0.13)",
    paper_2: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  background: {
    sidebarActions: "linear-gradient(180deg, #FEFEFE 76.87%, rgba(254, 254, 254, 0.2) 100%)",
    sidebarHeader: "linear-gradient(180deg, #FEFEFE 82.63%, rgba(254, 254, 254, 0) 100%);",
    callbackBannerBack: "linear-gradient(90.31deg, rgba(250, 248, 251, 0) 71.02%, #F9F9FB 83.72%)",
    callbackBannerFront:
      "linear-gradient(180.39deg, rgba(255, 255, 255, 0) 48.13%, #FFFFFF 57.26%)",
    callbackBannerFrontSmall:
      "linear-gradient(90deg, rgba(255, 255, 255, 0) 26.74%, rgba(255, 255, 255, 0.88) 31.3%, rgba(255, 255, 255, 0.92) 35.07%, #FFFFFF 36.93%)",
  },
  palette: {
    mode: "light",
    background: { default: "#ffffff" },

    snow: { main: "#F9F9FA" },
    arctic: { main: "#fbfdff" },
    back: { main: "#e3e9ed" },
    back40: { main: "#f0f2f4" },
    grape: { main: "#b4c1d5" },
    grape30: { main: "#e4e8ee" },
    bloom: { main: "#4062ff" },
    lin: { main: "linear-gradient(91.86deg, #C0E9FF -4.05%, #FBFDFF 50.02%)" },
    placeholder: { main: "linear-gradient(91.86deg, #dcdedf -4.05%, #fbfdff 50.02%)" },

    const: { main: "#ffffff" },
    walter: { main: "#fefefe" },
    wall: { main: "#f9f9f9" },
    gary: { main: "#616161" },
    cow: { main: "#2b2b2b" },
    total: { main: "#181818" },

    sparrow: { main: "#ff3030" },
    eye: { main: "#3ac922" },

    marian: { main: "#3f88b4" },
    ocean: { main: "#0096d1" },
    pool: { main: "#00b4d8" },
    bikiniBottom: { main: "#3ebdc6" },
    wave: { main: "#52ada2" },
    icicle: { main: "#a8ead5" },
    plant: { main: "#aade87" },
    grass: { main: "#9ccd62" },
    daicy: { main: "#f0ec73" },
    banana: { main: "#fbdb14" },
    icarus: { main: "#fbbc58" },
    griffin: { main: "#ff8b0d" },
    sun: { main: "#f57b51" },
    mary: { main: "#f15e3e" },
    tallaringa: { main: "#d66d6c" },
    california: { main: "#f7d3c3" },
    fiji: { main: "#ef929e" },
    ametist: { main: "#8e6c90" },
    polumne: { main: "#a994a7" },
    lavander: { main: "#7078b9" },
  },
};

export function createAppTheme(mode: PaletteMode = "light"): ThemeOptions {
  if (mode === "dark") {
    return deepmerge(BaseTheme, DarkTheme);
  }

  return deepmerge(BaseTheme, LightTheme);
}

export function isValidThemeMode(themeMode: string): boolean {
  const availableThemeModes = ["dark", "light"];

  return availableThemeModes.includes(themeMode);
}

export type Theme = MuiTheme;

export function useTheme() {
  return useMuiTheme<Theme>();
}

export function makeStyles<Props extends object = {}, ClassKey extends string = string>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, "withTheme">,
): keyof Props extends never
  ? (props?: any) => ClassNameMap<ClassKey>
  : (props: Props) => ClassNameMap<ClassKey> {
  return muiMakeStyles<Theme, Props, ClassKey>(styles, options);
}

export function withStyles<
  StylesType extends Styles<any, any>,
  Options extends WithStylesOptions<ThemeOfStyles<StylesType>> = {},
>(
  style: StylesType,
  options?: Options,
): PropInjector<
  WithStyles<StylesType, Options["withTheme"]>,
  StyledComponentProps<ClassKeyOfStyles<StylesType>> & PropsOfStyles<StylesType>
> {
  return muiWithStyles(style, options);
}

export function useMediaQuery(
  queryInput: string | ((theme: Theme) => string),
  options?: Options,
): boolean {
  const theme = useTheme();

  const query = isString(queryInput) ? queryInput : queryInput(theme);

  return useMediaQueryMui(query, options);
}

export const styled = createStyled({ defaultTheme: createAppTheme() as Theme });

export function useIsLaptopUp(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.up(Breakpoints.Laptop));
}

export function useIsLaptopDown(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.down(Breakpoints.Laptop));
}

export function useIsLaptopOnly(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.only(Breakpoints.Laptop));
}

export function useIsMediumOnly(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.only(Breakpoints.Laptop));
}

export function useIsDesktopUp(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.up(Breakpoints.Desktop));
}

export function useIsDesktopDown(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.down(Breakpoints.Desktop));
}

export function useIsMobileUp(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.up(Breakpoints.Mobile));
}

export function useIsMobileDown(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.down(Breakpoints.Mobile));
}

export function useIsMobileOnly(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.only(Breakpoints.Mobile));
}

export function useIsTabletUp(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.up(Breakpoints.Tablet));
}

export function useIsTabletDown(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.down(Breakpoints.Tablet));
}

export function useIsTabletOnly(): boolean {
  return useMediaQuery((theme) => theme.breakpoints.only(Breakpoints.Tablet));
}

export function useIsMobileDevice() {
  const isSmallDown = useIsTabletDown();

  return isSmallDown;
}

export function useIsTablet() {
  return useMediaQuery((theme) => theme.breakpoints.only(Breakpoints.Tablet));
}

export function useIsDarkTheme(): boolean {
  return useMediaQuery("(prefers-color-scheme: dark)");
}

export function useIsLightTheme(): boolean {
  return useMediaQuery("(prefers-color-scheme: light)");
}
