import { DateTime } from "luxon";
import { forwardRef, useEffect, useMemo, useState } from "react";

import { makeStyles, useTheme } from "../../theme/Theme";
import { DatePickerMonths } from "./DatePickerMonths";
import { DatePickerWeekDays } from "./DatePickerWeekDays";
import { DatePickerCalendar } from "./DatePickerCalendar";
import { ActionButton, ButtonType } from "../ui/ActionButton";
import { DatePickerRangeButton } from "./DatePickerRangeButton";
import { normalizeDateTime, sameDate } from "../../utils";
import { Box, BoxProps } from "@mui/material";
import { useLocation } from "react-router-dom";
import { mapSearchStringToObject } from "../../helpers/FormatHelpers";
import { DatePickerSelectYear } from "./DatePickerSelectYear";
import { DatePickerSelectMonth } from "./DatePickerSelectMonth";
import useDate from "../../hooks/useDate";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  calendar: { marginBottom: theme.typography.pxToRem(26) },
  presetsContainer: { display: "flex", marginBottom: theme.typography.pxToRem(16) },
  presetButton: {
    "&.MuiButton-root": {
      height: theme.typography.pxToRem(33),

      "&:not(:last-child)": {
        marginRight: theme.typography.pxToRem(10),
      },
    },
  },
  rangeContainer: {
    display: "flex",
    marginBottom: theme.typography.pxToRem(20),
    margin: "0 auto",
    marginTop: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(300),
  },
  rangeButton: {
    flex: 1,
    "&:first-child": {
      marginRight: theme.typography.pxToRem(9),
    },
    "&:last-child": {
      marginLeft: theme.typography.pxToRem(9),
    },
  },
}));

export enum DatePickerType {
  Range = "range",
  Default = "default",
}

export interface DatePickerProps extends Omit<BoxProps, "onChange"> {
  /* Minimum selectable date */
  readonly minDate: DateTime;
  /* Maximum selectable date. If given undefined, infinity implied */
  readonly maxDate?: DateTime;
  /*
   * Array of length 2.
   *
   * @Range type
   * Value at index 1 is start of the range, value at index 2 - end of the range.
   *
   * @Default type
   * Values at both indices are considered to be a selected date.
   */
  readonly value: string[] | Date[] | DateTime[] | null;
  readonly type?: DatePickerType;
  readonly onChange: (date?: string, date2?: string) => void;
  readonly withApplyButton?: boolean;
  readonly initialMaxDate?: DateTime;
}

export const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      minDate,
      maxDate,
      withApplyButton = true,
      type = DatePickerType.Default,
      initialMaxDate: initialMaxDateProp,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    const { INITIAL_MAX_DATE } = useDate();
    const initialMaxDate = initialMaxDateProp ?? INITIAL_MAX_DATE;

    const [activeRange, setActiveRange] = useState(0);

    const [showTypeSelect, setShowTypeSelect] = useState<Common.PeriodType>("day");

    const [tempDateTime, setTempDateTime] = useState<DateTime>(initialMaxDate);

    const [endDateRange, setEndDateRange] = useState<DateTime | undefined>();
    const [startDateRange, setStartDateRange] = useState<DateTime | undefined>();

    const isRemovePxByContent = showTypeSelect === "year";

    const isRangeType = type === DatePickerType.Range;
    const period = useMemo(() => {
      if (!startDateRange || !endDateRange) {
        return;
      }

      if (endDateRange.minus({ month: 1 }).toMillis() === startDateRange.toMillis()) {
        return "month";
      }
      if (endDateRange.minus({ year: 1 }).toMillis() === startDateRange.toMillis()) {
        return "year";
      }
      if (minDate && sameDate(initialMaxDate, endDateRange) && sameDate(startDateRange, minDate)) {
        return "all";
      }
    }, [startDateRange, endDateRange, minDate, initialMaxDate]);

    const changeDateHandler = (date?: DateTime) => {
      if (!date) {
        return;
      }

      if (!withApplyButton) {
        onChange(date.toISO());
      }

      if (activeRange === 0) {
        const startDateMoreEndDate = endDateRange
          ? date.toMillis() > endDateRange.toMillis()
          : false;

        if (startDateMoreEndDate) {
          setEndDateRange(date);
        }

        setStartDateRange(date);
      } else {
        const endDateLessStartDate = startDateRange
          ? date.toMillis() < startDateRange.toMillis()
          : false;

        if (endDateLessStartDate) {
          setStartDateRange(date);
        }

        setEndDateRange(date);
      }

      setTempDateTime(date);
    };

    const resetToDefault = () => {
      setEndDateRange(undefined);
      setStartDateRange(undefined);
    };

    const resetToDefaultShowSelectType = () => {
      setShowTypeSelect("day");
    };

    const handlerYearSelect = (year: number) => {
      setTempDateTime(tempDateTime?.set({ year }));
      setShowTypeSelect("month");
    };
    const handlerMonthSelect = (month: number) => {
      setTempDateTime(tempDateTime?.set({ month }));
      setShowTypeSelect("day");
    };

    useEffect(() => {
      if (activeRange === 0 && startDateRange) {
        setTempDateTime(startDateRange);
      }
    }, [startDateRange, activeRange]);

    useEffect(() => {
      if (activeRange === 1 && endDateRange) {
        setTempDateTime(endDateRange);
      }
    }, [endDateRange, activeRange]);

    useEffect(() => {
      const dates = mapSearchStringToObject(location.search);

      if (isRangeType && dates.fromDate != null && dates.toDate != null) {
        setStartDateRange(DateTime.fromISO(dates.fromDate));
        setEndDateRange(DateTime.fromISO(dates.toDate));
      } else if (!isRangeType && dates.date != null) {
        setStartDateRange(DateTime.fromISO(dates.date));
      }
    }, []);

    useEffect(() => {
      const first = value?.[0];
      const second = value?.[1];

      if (first != null) {
        setStartDateRange(normalizeDateTime(first));
      }

      if (second != null) {
        setEndDateRange(normalizeDateTime(second));
      }
    }, []);

    return (
      <Box
        ref={ref}
        {...props}
        px={isRemovePxByContent ? 0 : props.px}
        className={classes.root}
        sx={{ overflow: "visible", ...props.sx }}
      >
        {isRangeType && (
          <div className={classes.rangeContainer}>
            <DatePickerRangeButton
              title="Начало"
              date={startDateRange}
              active={activeRange === 0}
              className={classes.rangeButton}
              onClick={() => {
                setActiveRange(0);

                if (startDateRange) {
                  setTempDateTime(startDateRange);
                }
              }}
            />
            <DatePickerRangeButton
              title="Конец"
              date={endDateRange}
              active={activeRange === 1}
              className={classes.rangeButton}
              onClick={() => {
                setActiveRange(1);

                if (!endDateRange) {
                  if (startDateRange) {
                    setTempDateTime(startDateRange);
                  }
                } else {
                  setTempDateTime(endDateRange);
                }
              }}
            />
          </div>
        )}

        <>
          <DatePickerMonths
            date={tempDateTime}
            minDate={minDate}
            showTypeSelect={showTypeSelect}
            maxDate={maxDate}
            resetShow={resetToDefaultShowSelectType}
            handlerYearSelect={() => setShowTypeSelect("year")}
            onNextMonthClick={() => setTempDateTime(tempDateTime?.plus({ month: 1 }))}
            onPrevMonthClick={() => setTempDateTime(tempDateTime?.minus({ month: 1 }))}
          />

          {showTypeSelect === "month" && (
            <DatePickerSelectMonth month={tempDateTime?.month} onChangeMonth={handlerMonthSelect} />
          )}

          {showTypeSelect === "year" && (
            <DatePickerSelectYear
              year={tempDateTime?.year}
              onChangeYear={handlerYearSelect}
              minDate={minDate}
            />
          )}

          {showTypeSelect === "day" && (
            <>
              <DatePickerWeekDays />
              <DatePickerCalendar
                minDate={minDate}
                maxDate={maxDate}
                date={tempDateTime}
                isRangeType={isRangeType}
                className={classes.calendar}
                activeEndDate={endDateRange}
                activeStartDate={startDateRange}
                onChange={(date) => changeDateHandler(date)}
              />
            </>
          )}

          {isRangeType && showTypeSelect === "day" && (
            <div className={classes.presetsContainer}>
              <ActionButton
                className={classes.presetButton}
                buttonType={period === "month" ? ButtonType.Primary : ButtonType.Secondary}
                onClick={() => {
                  if (period === "month") {
                    resetToDefault();
                  } else if (endDateRange) {
                    setStartDateRange(endDateRange.minus({ month: 1 }));
                  } else {
                    const startDate = startDateRange ?? initialMaxDate;

                    setEndDateRange(startDate);
                    setStartDateRange(startDate.minus({ month: 1 }));
                  }
                }}
              >
                Месяц
              </ActionButton>
              <ActionButton
                className={classes.presetButton}
                buttonType={period === "year" ? ButtonType.Primary : ButtonType.Secondary}
                onClick={() => {
                  if (period === "year") {
                    resetToDefault();
                  } else if (endDateRange) {
                    setStartDateRange(endDateRange.minus({ year: 1 }));
                  } else {
                    const startDate = startDateRange ?? initialMaxDate;

                    setEndDateRange(startDate);
                    setStartDateRange(startDate.minus({ year: 1 }));
                  }
                }}
              >
                Год
              </ActionButton>
              <ActionButton
                className={classes.presetButton}
                buttonType={period === "all" ? ButtonType.Primary : ButtonType.Secondary}
                onClick={() => {
                  if (period === "all") {
                    resetToDefault();
                  } else if (!minDate) {
                    if (endDateRange) {
                      setStartDateRange(endDateRange.minus({ year: 1 }));
                    } else {
                      const startDate = startDateRange ?? initialMaxDate;

                      setEndDateRange(startDate);
                      setStartDateRange(startDate?.minus({ year: 1 }));
                    }
                  } else {
                    setStartDateRange(minDate);
                    setEndDateRange(maxDate ?? initialMaxDate);
                  }
                }}
              >
                Все время
              </ActionButton>
            </div>
          )}

          {withApplyButton && showTypeSelect === "day" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.walter.main,
              }}
            >
              <ActionButton
                disabled={isRangeType ? !startDateRange || !endDateRange : !startDateRange}
                onClick={() => onChange(startDateRange?.toISO(), endDateRange?.toISO())}
              >
                Сохранить
              </ActionButton>
            </Box>
          )}
        </>
      </Box>
    );
  },
);
