import { Box } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "../../theme/Theme";
import { memo } from "react";

interface Props {
  isActive: boolean;
}

const useStyles = makeStyles((theme) => ({
  line: {
    width: "100%",
    height: 4,
    borderRadius: 4,
    background: theme.palette.back40.main,
    overflow: "hidden",
    position: "relative",
  },
  lineFill: {
    background: theme.palette.bloom.main,
    position: "absolute",
    top: 0,
    left: 0,
    height: 4,
    transition: "width 0.5s ease-in-out, opacity 0.5s ease-in-out",
    width: "0%",
    opacity: 0,
  },

  lineFill_active: {
    width: "100%",
    opacity: 1,
  },
}));

export const AuthLineProgressItem = memo(({ isActive }: Props) => {
  const styles = useStyles();
  return (
    <Box className={styles.line}>
      <div
        className={classNames(styles.lineFill, {
          [styles.lineFill_active]: isActive,
        })}
      />
    </Box>
  );
});
